"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    width: {
      type: [String, Number],
      default: '100px'
    },
    height: {
      type: [String, Number],
      default: '30px'
    },
    padding: {
      type: [String, Number],
      default: '6px'
    },
    margin: {
      type: [String, Number],
      default: '0'
    },
    group: {
      type: [String, Number],
      default: 'choose'
    },
    data: {
      type: Array
    },
    title: {
      type: String,
      default: ''
    }
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    select: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
        this.$emit('changeType', value);
      }
    }
  },
  methods: {
    is_active: function is_active(item) {
      if (item.active) {
        if (item.value === this.value) {
          return true;
        }
      }
      return false;
    }
  }
};