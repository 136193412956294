var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "no-img item-flex-center",
      style: {
        background: "#eaeef4",
        width: 750 / 2 + "px",
        height: 428 / 2 + "px",
        "border-radius":
          _vm.facade.topLeftRadius / 2 +
          "px " +
          _vm.facade.topRightRadius / 2 +
          "px " +
          _vm.facade.bottomRightRadius / 2 +
          "px " +
          _vm.facade.bottomLeftRadius / 2 +
          "px",
      },
    },
    [
      _c("img", {
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
          alt: "",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }