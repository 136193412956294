"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  namespaced: true,
  state: {
    menuList: {}
  },
  mutations: {
    /**
     * 设置分类
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setmenuList: function setmenuList(state, _ref) {
      var key = _ref.key,
        data = _ref.data;
      state.menuList[key] = data;
    }
  }
};