var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-button",
        {
          staticClass: "mr20",
          attrs: { size: "mini", icon: "el-icon-back" },
          on: { click: _vm.close },
        },
        [_vm._v("返回")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "min-height": "100vh" } },
        [
          _vm.detailjson.orderProduct
            ? _c(
                "el-table",
                {
                  staticStyle: { "margin-top": "15px" },
                  attrs: { size: "mini", data: _vm.detailjson.orderProduct },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "图片" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "demo-image__preview" },
                                [
                                  _c("el-image", {
                                    attrs: {
                                      src:
                                        scope.row.productItem.image + "!120a",
                                      "preview-src-list": [
                                        scope.row.productItem.image,
                                      ],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2593641630
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "拍品名称",
                      prop: "productItem.store_name",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "作者", prop: "productItem.brand" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.productItem.brand
                                ? _c("div", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          scope.row.productItem.brand.brand_name
                                        ) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2235058083
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "分类" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.productItem.cate_parent &&
                              scope.row.productItem.cate_parent[0]
                                ? _c("div", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          scope.row.productItem.cate_parent[0]
                                            .cate_name
                                        ) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1214839979
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "标签", prop: "productItem.label" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "货架" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.shelf
                                ? _c("div", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(scope.row.shelf.shelf_no) +
                                        "\n                "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3504163178
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "属性" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                _vm._l(
                                  scope.row.productItem.attrInfo,
                                  function (m, i) {
                                    return _c("span", { key: "oattr_" + i }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(m.attr_name) +
                                          ":" +
                                          _vm._s(m.attr_value_name) +
                                          "\n                  "
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1395830656
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }