"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _systemForm = require("@/api/systemForm");
var _video = require("@/api/video");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserGroup',
  data: function data() {
    return {
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        type: 2,
        is_group: 0
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tagTypeList: [],
      editCateData: {
        show: false,
        name: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入分类名称',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _video.getTagTypeApi)().then(function (res) {
      console.log(res);
      _this.tagTypeList = res.data;
    });
    this.getList();
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _video.getTagAndTypesListApi)(this.tableFrom).then(function (res) {
        console.log(res);
        _this2.tableData.data = res.data.rows;
        _this2.tableData.total = res.data.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    // 添加
    onAdd: function onAdd() {
      this.editCateData = {
        show: true,
        name: '',
        type: Number(this.tableFrom.type)
      };
    },
    // 编辑
    onEdit: function onEdit(m) {
      this.editCateData = {
        show: true,
        name: m.name,
        tag_id: m.id,
        type: m.type_id,
        tag_key: m.tag_key
      };
    },
    saveAction: function saveAction() {
      var _this3 = this;
      if (!this.editCateData.name) {
        this.$message.error('请输入标签名称');
        return false;
      }
      this.$confirm("\u786E\u8BA4".concat(this.editCateData.id ? '修改' : '添加', "\u8BE5\u6807\u7B7E?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // let action = labelAddApi;
        // if (this.editCateData.tag_id) {
        //   action = labelEditApi;
        // }
        (0, _video.getTagSaveUpdApi)(_this3.editCateData).then(function () {
          _this3.$message.success('操作成功');
          _this3.getList();
          _this3.editCateData.show = false;
        }).catch(function (_ref) {
          var message = _ref.message;
        });
      }).catch(function () {});
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this4 = this;
      this.$modalSure().then(function () {
        (0, _video.getTagDeleteApi)(id).then(function (_ref2) {
          var message = _ref2.message;
          _this4.$message.success('操作成功');
          _this4.tableData.data.splice(idx, 1);
        }).catch(function (_ref3) {
          var message = _ref3.message;
        });
      });
    }
  }
};