"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _index = require("./index");
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    lat: {
      type: Number,
      default: 34.34127
    },
    lon: {
      type: Number,
      default: 108.93984
    },
    mapKey: {
      tyep: String
    },
    address: {
      tyep: String
    }
  },
  data: function data() {
    return {
      geocoder: undefined,
      marker: null,
      resultDatail: {}
    };
  },
  created: function created() {
    this.initMap();
  },
  methods: {
    //初始化地图
    initMap: function initMap() {
      var _this = this;
      (0, _index.TMap)(this.mapKey).then(function (qq) {
        var map;
        var center = new qq.maps.LatLng(_this.lat, _this.lon);
        map = new qq.maps.Map(document.getElementById("container"), {
          // center: center,
          zoom: 15
        });
        //调用地址解析类
        _this.geocoder = new qq.maps.Geocoder({
          complete: function complete(result) {
            map.setCenter(result.detail.location);
            _this.marker = new qq.maps.Marker({
              map: map,
              position: result.detail.location
            });
            _this.resultDatail = result.detail;
            _this.$emit("getCoordinates", result.detail);
          }
        });
        console.log(_this.address);
        _this.geocoder.getAddress(center);

        // this.geocoder.getLocation(this.address);
        // 开启监听点击
        qq.maps.event.addListener(map, "click", function (event) {
          _this.marker.setMap(null);
          _this.marker.position = {
            lat: event.latLng.getLat(),
            lng: event.latLng.getLng()
          };
          var coord = new qq.maps.LatLng(event.latLng.getLat(), event.latLng.getLng());
          _this.geocoder.getAddress(coord);
        });
      });
    },
    // 搜索地点
    searchKeyword: function searchKeyword() {
      var address = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "西安";
      this.marker.setMap(null);
      this.geocoder.getLocation(address);
    }
  }
};