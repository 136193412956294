var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "btn-view" },
    [
      [
        _c("div", { staticClass: "flex align-items-c" }, [
          _c(
            "div",
            {
              staticClass: "flex-one l",
              class: { "no-radius": _vm.facade.style === 2 },
              style: _vm.getStyle("l"),
            },
            [_vm._v("加入购物车")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex-one r",
              class: {
                ml10: _vm.facade.style === 1,
                "no-radius": _vm.facade.style === 2,
              },
              style: _vm.getStyle("r"),
            },
            [_vm._v("立即购买")]
          ),
        ]),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }