var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "flex align-items-c mt10" }, [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
            _vm._v("内容类型："),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml5" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "medium" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.contentLatitudeId,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "contentLatitudeId", $$v)
                    },
                    expression: "tableFrom.contentLatitudeId",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "" } }, [
                    _vm._v("全部"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.weidu.sysAttrValueList, function (item, index) {
                    return _c(
                      "el-radio-button",
                      { key: "" + index, attrs: { label: item.id } },
                      [_vm._v(_vm._s(item.attrValueName))]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex align-items-c mt20" }, [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
            _vm._v("内容定义："),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml5" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "medium" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.contentLocalizationId,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "contentLocalizationId", $$v)
                    },
                    expression: "tableFrom.contentLocalizationId",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "" } }, [
                    _vm._v("全部"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.dingwei.sysAttrValueList, function (item, index) {
                    return _c(
                      "el-radio-button",
                      { key: "" + index, attrs: { label: item.id } },
                      [_vm._v(_vm._s(item.attrValueName))]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex ml20" }, [
          _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
            _vm._v("画面比例："),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml5" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "medium" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.ratio,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "ratio", $$v)
                    },
                    expression: "tableFrom.ratio",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "" } }, [
                    _vm._v("全部"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "9:16" } }, [
                    _vm._v("9:16"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "16:9" } }, [
                    _vm._v("16:9"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "3:4" } }, [
                    _vm._v("3:4"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex align-items-c mt20" }, [
        _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
          _vm._v("作品分类："),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml5" },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "medium" },
                on: { change: _vm.changeTwoCategorySearch },
                model: {
                  value: _vm.tableFrom.firstCategoryId,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "firstCategoryId", $$v)
                  },
                  expression: "tableFrom.firstCategoryId",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "" } }, [
                  _vm._v("全部"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.categoryList, function (item, index) {
                  return _c(
                    "el-radio-button",
                    { key: "" + index, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex align-items-c mt20" }, [
        _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
          _vm._v("二级分类："),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml5" },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "medium" },
                on: {
                  change: function ($event) {
                    return _vm.getList(1)
                  },
                },
                model: {
                  value: _vm.tableFrom.secondaryCategoryId,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "secondaryCategoryId", $$v)
                  },
                  expression: "tableFrom.secondaryCategoryId",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "" } }, [
                  _vm._v("全部"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.categoryTwoList, function (item, index) {
                  return _c(
                    "el-radio-button",
                    { key: "" + index, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex align-items-c mt20" }, [
        _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
          _vm._v("作品形状："),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml5" },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "medium" },
                on: {
                  change: function ($event) {
                    return _vm.getList(1)
                  },
                },
                model: {
                  value: _vm.tableFrom.workShapeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "workShapeId", $$v)
                  },
                  expression: "tableFrom.workShapeId",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "" } }, [
                  _vm._v("全部"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.xingzhuang.sysAttrValueList, function (item, index) {
                  return _c(
                    "el-radio-button",
                    { key: "" + index, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.attrValueName))]
                  )
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex align-items-c mt20" }, [
        _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
          _vm._v("模版标签："),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml5" },
          [
            _c(
              "el-checkbox-group",
              {
                attrs: { size: "medium" },
                on: {
                  change: function ($event) {
                    return _vm.getList(1)
                  },
                },
                model: {
                  value: _vm.tagIdList,
                  callback: function ($$v) {
                    _vm.tagIdList = $$v
                  },
                  expression: "tagIdList",
                },
              },
              _vm._l(_vm.tagList, function (item, index) {
                return _c(
                  "el-checkbox-button",
                  { key: index, attrs: { label: item.id } },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex flex-wrap mt20" },
        [
          _c(
            "div",
            { staticClass: "mr20" },
            [
              _c("el-input", {
                staticClass: "w200",
                attrs: { placeholder: "请输入组件名称", clearable: "" },
                on: {
                  change: function ($event) {
                    return _vm.getList(1)
                  },
                },
                model: {
                  value: _vm.tableFrom.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "name", $$v)
                  },
                  expression: "tableFrom.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mr20" },
            [
              _c(
                "el-select",
                {
                  staticClass: "w200",
                  attrs: { placeholder: "请选择组件类型", clearable: "" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.templateType,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "templateType", $$v)
                    },
                    expression: "tableFrom.templateType",
                  },
                },
                _vm._l(_vm.categores, function (m, i) {
                  return _c("el-option", {
                    key: i,
                    attrs: { value: m.value, label: m.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mr20" },
            [
              _c(
                "el-select",
                {
                  staticClass: "w200",
                  attrs: { placeholder: "组件用途", clearable: "" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.purpose,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "purpose", $$v)
                    },
                    expression: "tableFrom.purpose",
                  },
                },
                _vm._l(_vm.types, function (m, i) {
                  return _c("el-option", {
                    key: i,
                    attrs: { value: m.value, label: m.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.onAdd },
            },
            [_vm._v("创建视频组件")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.data, size: "small", "row-key": "id" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "160" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "name", label: "组件图片", width: "70" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.image
                      ? _c("div", [
                          _c("img", {
                            staticStyle: {
                              height: "50px",
                              width: "50px",
                              "object-fit": "contain",
                            },
                            attrs: { src: scope.row.image },
                          }),
                        ])
                      : _c("div", [_vm._v("生成中")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "name", label: "组件名称", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "name", label: "预览视频", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.videoStatus == 2
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.previewVideo(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看视频")]
                            ),
                          ],
                          1
                        )
                      : scope.row.videoStatus == 1
                      ? _c("div", [_vm._v("生成中")])
                      : scope.row.videoStatus == 0
                      ? _c("div", [_vm._v("待生成")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "name", label: "画面比例", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.ratio))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "type", label: "组件类型", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.categoresVals.indexOf(scope.row.templateType) !== -1
                      ? _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.categores[
                                _vm.categoresVals.indexOf(
                                  scope.row.templateType
                                )
                              ].label
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "name", label: "分类", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      _vm._l(scope.row.categoryIds, function (item, index) {
                        return _c("span", { key: scope.row.id + "" + index }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.categoryJSON[item[item.length - 1]]
                                  ? _vm.categoryJSON[item[item.length - 1]] +
                                      ","
                                  : ""
                              ) +
                              "\n            "
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "作品形状", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(scope.row.workShape) +
                        "\n        "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "内容定义", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(scope.row.contentLocalization) +
                        "\n        "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "内容类型", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(scope.row.contentLatitude) +
                        "\n        "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "标签", "min-width": "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          scope.row.tagnames ? scope.row.tagnames.join(",") : ""
                        ) +
                        "\n        "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "是否开启配音", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(scope.row.isOpenDubbing ? "开启" : "未开启") +
                        "\n        "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "是否动态背景", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          scope.row.isDynamicBackgroundColor ? "开启" : "未开启"
                        ) +
                        "\n        "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "是否分组循环", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(scope.row.hasGroupingCycle ? "开启" : "未开启") +
                        "\n        "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "更新时间", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n          " +
                        _vm._s(scope.row.updateTime) +
                        "\n        "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "160",
              fixed: "right",
              align: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "flex justify-end" }, [
                      _c(
                        "div",
                        { staticStyle: { "margin-left": "4px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.editAction(scope.row)
                                },
                              },
                            },
                            [_vm._v("修改")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "margin-left": "4px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.copyAction(scope.row.id)
                                },
                              },
                            },
                            [_vm._v("复制")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "margin-left": "4px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.setAction(scope.row.id)
                                },
                              },
                            },
                            [_vm._v("设置组件")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "margin-left": "4px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(scope.row.id)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.pageSize,
              "current-page": _vm.tableFrom.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showVideo,
            title: "预览视频",
            "append-to-body": "",
            width: _vm.videoRatio == "16:9" ? "800px" : "500px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showVideo = $event
            },
          },
        },
        [
          _c("div", { staticClass: "item-flex-center" }, [
            _c("video", {
              staticStyle: { width: "100%" },
              attrs: { src: _vm.videoUrl, controls: "" },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.detail.show,
            title: _vm.detail.id ? "修改组件" : "创建组件",
            "append-to-body": "",
            width: "600px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.detail, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm ",
              attrs: {
                model: _vm.detail,
                rules: _vm.rules,
                "label-width": "110px",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "组件名称：" } }, [
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  [
                    _c("el-input", {
                      staticClass: "w200",
                      model: {
                        value: _vm.detail.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.detail, "name", $$v)
                        },
                        expression: "detail.name",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "组件类型：" } }, [
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "w200",
                        model: {
                          value: _vm.detail.templateType,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "templateType", $$v)
                          },
                          expression: "detail.templateType",
                        },
                      },
                      _vm._l(_vm.categores, function (m, i) {
                        return _c("el-option", {
                          key: i,
                          attrs: { value: m.value, label: m.label },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择分类：", prop: "firstCategoryId" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-cascader", {
                        attrs: {
                          options: _vm.categoryList,
                          props: {
                            multiple: true,
                            checkStrictly: false,
                            value: "id",
                            label: "label",
                            children: "children",
                          },
                          clearable: "",
                        },
                        model: {
                          value: _vm.categoryIds,
                          callback: function ($$v) {
                            _vm.categoryIds = $$v
                          },
                          expression: "categoryIds",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.attrList, function (m, i) {
                return _c(
                  "div",
                  { key: i },
                  [
                    m.attrName == "内容类型"
                      ? [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "内容类型：",
                                prop: "contentLatitudeId",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex align-items-c" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w200",
                                      model: {
                                        value: _vm.detail.contentLatitudeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detail,
                                            "contentLatitudeId",
                                            $$v
                                          )
                                        },
                                        expression: "detail.contentLatitudeId",
                                      },
                                    },
                                    _vm._l(
                                      m.sysAttrValueList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: "" + index + i,
                                          attrs: {
                                            value: item.id,
                                            label: item.attrValueName,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      : m.attrName == "内容定义"
                      ? [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "内容定义：",
                                prop: "contentLocalizationId",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex align-items-c" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w200",
                                      model: {
                                        value: _vm.detail.contentLocalizationId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detail,
                                            "contentLocalizationId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detail.contentLocalizationId",
                                      },
                                    },
                                    _vm._l(
                                      m.sysAttrValueList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: "" + index + i,
                                          attrs: {
                                            value: item.id,
                                            label: item.attrValueName,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      : m.attrName == "作品形状"
                      ? [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "作品形状：",
                                prop: "workShapeId",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex align-items-c" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w200",
                                      model: {
                                        value: _vm.detail.workShapeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detail,
                                            "workShapeId",
                                            $$v
                                          )
                                        },
                                        expression: "detail.workShapeId",
                                      },
                                    },
                                    _vm._l(
                                      m.sysAttrValueList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: "" + index + i,
                                          attrs: {
                                            value: item.id,
                                            label: item.attrValueName,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "标签：", prop: "workShapeId" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.detail.tagIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "tagIds", $$v)
                            },
                            expression: "detail.tagIds",
                          },
                        },
                        _vm._l(_vm.tagList, function (m, i) {
                          return _c(
                            "el-checkbox",
                            { key: i, attrs: { label: m.id } },
                            [_vm._v(_vm._s(m.name))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-form-item", { attrs: { label: "开启配音：" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "flex align-items-c",
                        staticStyle: { height: "36px" },
                      },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ccc",
                          },
                          model: {
                            value: _vm.detail.isOpenDubbing,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "isOpenDubbing", $$v)
                            },
                            expression: "detail.isOpenDubbing",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "动态背景：" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "flex align-items-c",
                        staticStyle: { height: "36px" },
                      },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ccc",
                          },
                          model: {
                            value: _vm.detail.isDynamicBackgroundColor,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.detail,
                                "isDynamicBackgroundColor",
                                $$v
                              )
                            },
                            expression: "detail.isDynamicBackgroundColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "分组循环：" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "flex align-items-c",
                        staticStyle: { height: "36px" },
                      },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ccc",
                          },
                          model: {
                            value: _vm.detail.hasGroupingCycle,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "hasGroupingCycle", $$v)
                            },
                            expression: "detail.hasGroupingCycle",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.detail.btnLoading },
                      on: {
                        click: function ($event) {
                          _vm.detail.show = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.detail.btnLoading,
                        type: "primary",
                      },
                      on: { click: _vm.confirmAction },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }