var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "layout-phone-header",
      style: {
        backgroundColor: _vm.headerSetting.header_background || "",
        backgroundImage:
          "url(" +
          (_vm.headerSetting.header_color === "white"
            ? "https://saas.cdn.yunzongbu.cn/image/20211026/9823014575b33347581d939efbd91aee.png"
            : "https://saas.cdn.yunzongbu.cn/image/20211026/a3459168515274a30097453656ad97b1.png") +
          ")",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "layout-phone-title item-align-center justify-center",
          style: { "z-index": 200, color: _vm.headerSetting.header_color },
        },
        [
          _c("div", { staticClass: "back" }, [
            _vm.headerSetting.header_color === "white"
              ? _c("img", {
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/image/20211029/ccfd6697386671c2bd471fb840c7fa22.png",
                    alt: "",
                  },
                })
              : _c("img", {
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/image/20211029/6f2d1b371322f39581c1c3d8cc2c730d.png",
                    alt: "",
                  },
                }),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              style: {
                fontSize: _vm.headerSetting.header_text_size / 2 + "px",
                fontWeight: _vm.headerSetting.fontWeight,
              },
            },
            [_vm._v("标题栏")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }