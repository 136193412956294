var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "flex mt10" }, [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
            _vm._v("来源："),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml5" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "medium" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.source_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "source_type", $$v)
                    },
                    expression: "tableFrom.source_type",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "3" } }, [
                    _vm._v("ArtAi"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("云总部"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "4" } }, [
                    _vm._v("IP星球"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex ml20" }, [
          _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
            _vm._v("业务场景："),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml5" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "medium" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.data_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "data_type", $$v)
                    },
                    expression: "tableFrom.data_type",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "" } }, [
                    _vm._v("全部"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.typeList, function (item, index) {
                    return _c(
                      "el-radio-button",
                      { key: index, attrs: { label: item.type } },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  _vm._v("=\n        "),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex mt10" }, [
        _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
          _vm._v("画面尺寸："),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml5 flex-one" },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "medium" },
                on: {
                  change: function ($event) {
                    return _vm.getList(1)
                  },
                },
                model: {
                  value: _vm.tableFrom.size,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "size", $$v)
                  },
                  expression: "tableFrom.size",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "" } }, [
                  _vm._v("全部"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.sizeList, function (item, index) {
                  return _c(
                    "el-radio-button",
                    { key: index, attrs: { label: item.actual } },
                    [_vm._v(_vm._s(item.actual) + " " + _vm._s(item.name))]
                  )
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex mt10" }, [
        _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
          _vm._v("模版标签："),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml5 flex-one" },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "medium" },
                on: {
                  change: function ($event) {
                    return _vm.getList(1)
                  },
                },
                model: {
                  value: _vm.tableFrom.tag_ids,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "tag_ids", $$v)
                  },
                  expression: "tableFrom.tag_ids",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "" } }, [
                  _vm._v("全部"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.tagList, function (item, index) {
                  return _c(
                    "el-radio-button",
                    { key: index, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                _vm._v("=\n      "),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex mt10" }, [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
            _vm._v("推荐："),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml5", staticStyle: { flex: "1" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "medium" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "status", $$v)
                    },
                    expression: "tableFrom.status",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "" } }, [
                    _vm._v("全部"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: 1 } }, [
                    _vm._v("未推荐"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: 2 } }, [
                    _vm._v("已推荐"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex ml20" }, [
          _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
            _vm._v("设计师："),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml5", staticStyle: { flex: "1" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "medium" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.create_user_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "create_user_id", $$v)
                    },
                    expression: "tableFrom.create_user_id",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "" } }, [
                    _vm._v("全部"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.adminarr, function (item, index) {
                    return _c(
                      "el-radio-button",
                      { key: index, attrs: { label: item.userId } },
                      [_vm._v(_vm._s(item.nickName))]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex ml20" }, [
          _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
            _vm._v("专属模版："),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml5", staticStyle: { flex: "1" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "medium" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.is_custom,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "is_custom", $$v)
                    },
                    expression: "tableFrom.is_custom",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "" } }, [
                    _vm._v("全部"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("是"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "2" } }, [
                    _vm._v("否"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex align-items-c mt20" }, [
        _c(
          "div",
          [
            _c(
              "el-input",
              {
                staticClass: "w300",
                attrs: { placeholder: "模版名称", clearable: "" },
                on: {
                  change: function ($event) {
                    return _vm.getList(1)
                  },
                },
                model: {
                  value: _vm.tableFrom.keyword,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "keyword", $$v)
                  },
                  expression: "tableFrom.keyword",
                },
              },
              [
                _c("el-button", {
                  attrs: { slot: "append", icon: "el-icon-search" },
                  slot: "append",
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml20" },
          [
            _c(
              "el-input",
              {
                staticClass: "w300",
                attrs: { placeholder: "请输入模版id", clearable: "" },
                on: {
                  change: function ($event) {
                    return _vm.getList(1)
                  },
                },
                model: {
                  value: _vm.tableFrom.template_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "template_id", $$v)
                  },
                  expression: "tableFrom.template_id",
                },
              },
              [
                _c("el-button", {
                  attrs: { slot: "append", icon: "el-icon-search" },
                  slot: "append",
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml20" },
          [
            _c("el-date-picker", {
              attrs: {
                "value-format": "yyyy-MM-dd HH:mm:ss",
                format: "yyyy-MM-dd HH:mm:ss",
                "start-placeholder": "选择开始时间",
                "end-placeholder": "选择结束时间",
                size: "small",
                type: "datetimerange",
              },
              on: {
                change: function ($event) {
                  return _vm.getList(1)
                },
              },
              model: {
                value: _vm.tableFrom.time,
                callback: function ($$v) {
                  _vm.$set(_vm.tableFrom, "time", $$v)
                },
                expression: "tableFrom.time",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { ref: "wapperRef", staticClass: "mt20" }, [
        _vm.gridList.length > 0
          ? _c(
              "div",
              {
                staticClass: "wapperBox",
                style: { height: _vm.wapperHeigth + "px" },
              },
              _vm._l(_vm.gridList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "wapper-item",
                    style: {
                      width: _vm.realWidth + "px",
                      height: "" + item.boxheight,
                      top: "" + item.top,
                      left: "" + item.left,
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "tjBut",
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.onRecommend(
                              item.id,
                              item.task_id,
                              item.status == 2 ? 0 : 1
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.status == 2 ? "已推荐" : "推荐"))]
                    ),
                    _vm._v(" "),
                    item.params.last_image
                      ? _c(
                          "el-button",
                          {
                            staticClass: "ggBut",
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.onUpd(item)
                              },
                            },
                          },
                          [_vm._v("改过")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("img", {
                      staticStyle: { width: "100%" },
                      attrs: { src: item.image, alt: "" },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "nameBox" }, [
                      _vm._v(
                        "模版名称：" +
                          _vm._s(
                            item.template_info ? item.template_info.name : ""
                          )
                      ),
                    ]),
                  ],
                  1
                )
              }),
              0
            )
          : _c("div", { staticClass: "noDataBox" }, [_vm._v("暂无数据")]),
        _vm._v(" "),
        _c("div", { attrs: { id: "footer" } }),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            title: "查看",
            "append-to-body": "",
            width: "850px",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex",
              staticStyle: {
                width: "100%",
                "justify-content": "space-between",
              },
            },
            [
              _c("div", [
                _c("div", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("改前"),
                ]),
                _vm._v(" "),
                _c("img", {
                  staticStyle: { width: "380px" },
                  attrs: { src: _vm.formState.last_image, alt: "" },
                }),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("改后"),
                ]),
                _vm._v(" "),
                _c("img", {
                  staticStyle: { width: "380px" },
                  attrs: { src: _vm.formState.image, alt: "" },
                }),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }