var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "flex align-items-c mt10" }, [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
            _vm._v("业务场景："),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml5" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "medium" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.businessScene,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "businessScene", $$v)
                    },
                    expression: "tableFrom.businessScene",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "" } }, [
                    _vm._v("全部"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("拍卖"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "2" } }, [
                    _vm._v("商品"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "3" } }, [
                    _vm._v("作品"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex ml20" }, [
          _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
            _vm._v("内容类型："),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml5" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "medium" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.contentType,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "contentType", $$v)
                    },
                    expression: "tableFrom.contentType",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "" } }, [
                    _vm._v("全部"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.weidu.sysAttrValueList, function (item, index) {
                    return _c(
                      "el-radio-button",
                      { key: "" + index, attrs: { label: item.id } },
                      [_vm._v(_vm._s(item.attrValueName))]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex align-items-c mt20" }, [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
            _vm._v("内容定义："),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml5" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "medium" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.contentDefine,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "contentDefine", $$v)
                    },
                    expression: "tableFrom.contentDefine",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "" } }, [
                    _vm._v("全部"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.dingwei.sysAttrValueList, function (item, index) {
                    return _c(
                      "el-radio-button",
                      { key: "" + index, attrs: { label: item.id } },
                      [_vm._v(_vm._s(item.attrValueName))]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex align-items-c mt20" }, [
        _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
          _vm._v("作品分类："),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml5" },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "medium" },
                on: { change: _vm.changeTwoCategory },
                model: {
                  value: _vm.tableFrom.category,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "category", $$v)
                  },
                  expression: "tableFrom.category",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "" } }, [
                  _vm._v("全部"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.categoryList, function (item, index) {
                  return _c(
                    "el-radio-button",
                    { key: "" + index, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex align-items-c mt20" }, [
        _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
          _vm._v("二级分类："),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml5" },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "medium" },
                on: {
                  change: function ($event) {
                    return _vm.getList(1)
                  },
                },
                model: {
                  value: _vm.tableFrom.subCategory,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "subCategory", $$v)
                  },
                  expression: "tableFrom.subCategory",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "" } }, [
                  _vm._v("全部"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.categoryTwoList, function (item, index) {
                  return _c(
                    "el-radio-button",
                    { key: "" + index, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex align-items-c mt20" }, [
        _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
          _vm._v("作品形状："),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml5" },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "medium" },
                on: {
                  change: function ($event) {
                    return _vm.getList(1)
                  },
                },
                model: {
                  value: _vm.tableFrom.shapeType,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "shapeType", $$v)
                  },
                  expression: "tableFrom.shapeType",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "" } }, [
                  _vm._v("全部"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.xingzhuang.sysAttrValueList, function (item, index) {
                  return _c(
                    "el-radio-button",
                    { key: "" + index, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.attrValueName))]
                  )
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex mt20" }, [
        _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
          _vm._v("标签："),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml5" },
          [
            _c(
              "el-checkbox-group",
              {
                attrs: { size: "medium" },
                on: {
                  change: function ($event) {
                    return _vm.getList(1)
                  },
                },
                model: {
                  value: _vm.tagIdList,
                  callback: function ($$v) {
                    _vm.tagIdList = $$v
                  },
                  expression: "tagIdList",
                },
              },
              _vm._l(_vm.tagList, function (item, index) {
                return _c(
                  "el-checkbox-button",
                  { key: index, attrs: { label: item.id } },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex mt20" }, [
        _c("div", { staticStyle: { "line-height": "36px", width: "70px" } }, [
          _vm._v("设计师："),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml5" },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "medium" },
                on: {
                  change: function ($event) {
                    return _vm.getList(1)
                  },
                },
                model: {
                  value: _vm.tableFrom.memberId,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "memberId", $$v)
                  },
                  expression: "tableFrom.memberId",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "" } }, [
                  _vm._v("全部"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.adminList, function (item, index) {
                  return _c(
                    "el-radio-button",
                    { key: "" + index, attrs: { label: item.userId } },
                    [_vm._v(_vm._s(item.nickName))]
                  )
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex align-items-c mt20" },
        [
          _c(
            "div",
            [
              _c(
                "el-input",
                {
                  staticClass: "w300",
                  attrs: { placeholder: "请输入模版名称", clearable: "" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.templateName,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "templateName", $$v)
                    },
                    expression: "tableFrom.templateName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "ml20",
              attrs: { type: "primary" },
              on: { click: _vm.onAdd },
            },
            [_vm._v("创建AE模版")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.data, size: "small", "row-key": "id" },
        },
        [
          _c("el-table-column", { attrs: { prop: "id", label: "ID" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "templateName",
              label: "模版名称",
              "min-width": "160",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "封面图", width: "60" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "demo-image__preview" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: { src: scope.row.coverImage, fit: "cover" },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "", label: "标准模版", "min-width": "160" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.standard
                      ? [
                          _c("div", [
                            _vm._v(_vm._s(scope.row.standard.fileName)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _vm._v(_vm._s(scope.row.standard.size) + " "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpd(
                                        scope.row.standard,
                                        scope.row.id,
                                        "standard"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("更新")]
                              ),
                            ],
                            1
                          ),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "", label: "动态模版", "min-width": "160" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.dynamics
                      ? [
                          _c("div", [
                            _vm._v(_vm._s(scope.row.dynamics.fileName)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _vm._v(_vm._s(scope.row.dynamics.size) + " "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpd(
                                        scope.row.dynamics,
                                        scope.row.id,
                                        "dynamics"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("更新")]
                              ),
                            ],
                            1
                          ),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "", label: "预览视频", width: "80" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.exampleVideo
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.onSee(scope.row.exampleVideo)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(scope.row.status == "0" ? "下架" : "上架")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "", label: "模版标签", "min-width": "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.labelList.join(",")))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "memberName", label: "创建人", width: "100" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: "创建时间",
              "min-width": "160",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "220", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        style: scope.row.recommend == 1 ? "color: red;" : "",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.editStatus(2, scope.row, "recommend")
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(scope.row.recommend == 0 ? "推荐" : "取消")
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        style: scope.row.status == 1 ? "color: red;" : "",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.editStatus(1, scope.row, "status")
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.status == 0 ? "上架" : "下架"))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.editAction(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.onBind(scope.row)
                          },
                        },
                      },
                      [_vm._v("绑定")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.onRend(scope.row)
                          },
                        },
                      },
                      [_vm._v("渲染")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.pageSize,
              "current-page": _vm.tableFrom.pageNum,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            title: "查看视频",
            "append-to-body": "",
            width: "600px",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c("video", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.videoUrl, controls: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visibleMoudle,
            title: "更新模版",
            "append-to-body": "",
            width: "600px",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleMoudle = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { staticClass: "demo-ruleForm", attrs: { "label-width": "80px" } },
            [
              _vm.ruleForm.filed == "standard"
                ? _c("el-form-item", { attrs: { label: "标准模版" } }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c(
                          "el-upload",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.uploadLoading,
                                expression: "uploadLoading",
                              },
                            ],
                            attrs: {
                              action: "",
                              "show-file-list": false,
                              "http-request": function (file) {
                                return _vm.uploadFile(file, "standardFileUrl")
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              { attrs: { size: "small", type: "primary" } },
                              [_vm._v("选择文件")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-upload",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.uploadLoading,
                                expression: "uploadLoading",
                              },
                            ],
                            staticStyle: { "margin-left": "40px" },
                            attrs: {
                              action: "",
                              "show-file-list": false,
                              "http-request": function (file) {
                                return _vm.setFile(file, "standardConfig")
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              { attrs: { size: "small", type: "primary" } },
                              [_vm._v("选择配置文件")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "margin-left": "20px" } }, [
                          _vm._v(_vm._s(_vm.ruleForm.standardConfigName)),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "filedName" }, [
                      _vm._v(_vm._s(_vm.ruleForm.standardFileUrlName)),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.filed == "dynamics"
                ? _c("el-form-item", { attrs: { label: "动态模版" } }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c(
                          "el-upload",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.uploadLoading,
                                expression: "uploadLoading",
                              },
                            ],
                            attrs: {
                              action: "",
                              "show-file-list": false,
                              "http-request": function (file) {
                                return _vm.uploadFile(file, "dynamicsFileUrl")
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              { attrs: { size: "small", type: "primary" } },
                              [_vm._v("选择文件")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-upload",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.uploadLoading,
                                expression: "uploadLoading",
                              },
                            ],
                            staticStyle: { "margin-left": "40px" },
                            attrs: {
                              action: "",
                              "show-file-list": false,
                              "http-request": function (file) {
                                return _vm.setFile(file, "dynamicsConfig")
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              { attrs: { size: "small", type: "primary" } },
                              [_vm._v("选择配置文件")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "margin-left": "20px" } }, [
                          _vm._v(_vm._s(_vm.ruleForm.dynamicsConfigName)),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "filedName" }, [
                      _vm._v(_vm._s(_vm.ruleForm.dynamicsFileUrlName)),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }