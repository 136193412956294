"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isPlatform: localStorage.getItem('identityid') === '11',
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      queryParams: {
        page: 1,
        size: 10
      },
      total: 0,
      workName: '',
      categoryName: '',
      searchLoading: false,
      categoryArticleList: [],
      announcerList: [],
      announcer: '',
      searchGoodsId: ''
    };
  },
  watch: {
    flag: function flag(val) {
      console.log(val);
      this.showSelectAuction = val;
    }
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    changeshow: function changeshow() {
      var _this = this;
      this.selectedAuction = JSON.parse(JSON.stringify(this.currentData));
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (this.categoryArticleList.length === 0) {
        var params = {
          type: 8
        };
        this.$http.get('/RecentlyAlbumList', {
          params: params
        }).then(function (res) {
          if (res.code === 200) {
            _this.categoryArticleList = res.data.data;
          }
        });
      }
      if (this.resultData.length === 0) {
        this.loadWork();
      }
      this.showSelectAuction = true;
    },
    searchPost: function searchPost(data) {
      var _this2 = this;
      var params = {
        uname: data,
        page: 1,
        size: 10
      };
      this.searchLoading = true;
      this.$http.get('/artiest_list', {
        params: params
      }).then(function (res) {
        if (res.code === 200) {
          _this2.announcerList = res.data.data;
        }
        _this2.searchLoading = false;
      });
    },
    searchWorkAction: function searchWorkAction() {
      this.queryParams.albumid = this.categoryName;
      this.queryParams.topictitle = this.workName;
      this.queryParams.page = 1;
      this.queryParams.postuid = this.announcer;
      this.loadWork();
    },
    loadWork: function loadWork(fun) {
      var _this3 = this;
      var params = this.queryParams;
      var msg = this.$message.loading({
        render: function render(h) {
          return h('span', ['加载中...']);
        },
        duration: 0
      });
      this.$http.get('/artiest_works_examine_list', {
        params: params
      }).then(function (res) {
        msg();
        if (res.code === 200) {
          _this3.total = res.data.total;
          res.data.data.forEach(function (m) {
            var newM = m;
            newM.photo = m.photos.split(',')[0];
          });
          _this3.resultData = res.data.data;
          _this3.$nextTick(function () {
            // 赋值后马上更新
            if (fun) fun();
          });
        }
      });
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (e) e.preventDefault();
    },
    auctionSelectedAction: function auctionSelectedAction(e, index) {
      var info = this.resultData[index];
      var data = {
        image: info.photos.split(',')[0],
        name: info.title,
        id: info.id,
        tags: [],
        price: '',
        sign: ''
      };
      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      e.preventDefault();
    },
    cursorSelect: function cursorSelect() {
      this.$emit('selectMessage', {
        type: 'work',
        data: this.selectedAuction
      });
    },
    changePageSize: function changePageSize(size) {
      this.queryParams.size = size;
      this.queryParams.page = 1;
      this.loadWork();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      this.loadWork();
    }
  }
};