var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "盲盒封面图：", required: "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "正方形图片" },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1", "showimage")
                                },
                              },
                            },
                            [
                              _vm.formValidate.image
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.formValidate.image + "!120a",
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("盒子展示图")]),
                                  ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-form-item", { attrs: { label: "盲盒展示图：" } }, [
                        _c("div", { staticStyle: { display: "flex" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "正方形图片" },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1", "left")
                                },
                              },
                            },
                            [
                              _vm.formValidate.leftimage
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.formValidate.leftimage + "!120a",
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("盒子左侧图")]),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "正方形图片" },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1", "right")
                                },
                              },
                            },
                            [
                              _vm.formValidate.rightimage
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.formValidate.rightimage + "!120a",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "el-icon-error btndel",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleRemove("rightimage")
                                        },
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("盒子右侧图")]),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "正方形图片" },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1", "top")
                                },
                              },
                            },
                            [
                              _vm.formValidate.topimage
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.formValidate.topimage + "!120a",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "el-icon-error btndel",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleRemove("topimage")
                                        },
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("盒子顶部图")]),
                                  ]),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "盲盒名称：", prop: "title" } },
                        [
                          _c("el-input", {
                            staticClass: "selwidth",
                            attrs: { placeholder: "请输入盲盒名称" },
                            model: {
                              value: _vm.formValidate.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "title", $$v)
                              },
                              expression: "formValidate.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "发售时间：", prop: "start_time" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "selwidth",
                            attrs: {
                              type: "datetime",
                              placeholder: "选择发售时间",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.formValidate.start_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "start_time", $$v)
                              },
                              expression: "formValidate.start_time",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "发售数量：", prop: "issuer_num" } },
                        [
                          _c("el-input-number", {
                            staticClass: "selwidth",
                            attrs: { min: 1, step: 1 },
                            model: {
                              value: _vm.formValidate.issuer_num,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "issuer_num", $$v)
                              },
                              expression: "formValidate.issuer_num",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "预留数量：",
                            prop: "reserved_number",
                          },
                        },
                        [
                          _c("el-input-number", {
                            staticClass: "selwidth",
                            attrs: { step: 1 },
                            model: {
                              value: _vm.formValidate.reserved_number,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "reserved_number",
                                  $$v
                                )
                              },
                              expression: "formValidate.reserved_number",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "限购数量：",
                            prop: "buy_limit_number",
                          },
                        },
                        [
                          _c("el-input-number", {
                            staticClass: "selwidth",
                            attrs: { step: 1 },
                            model: {
                              value: _vm.formValidate.buy_limit_number,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "buy_limit_number",
                                  $$v
                                )
                              },
                              expression: "formValidate.buy_limit_number",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "盲盒系列：", prop: "album_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selwidth",
                              attrs: {
                                placeholder: "请选择盲盒系列",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.formValidate.album_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "album_id", $$v)
                                },
                                expression: "formValidate.album_id",
                              },
                            },
                            _vm._l(_vm.albumlist, function (item) {
                              return _c("el-option", {
                                key: item.album_id,
                                attrs: {
                                  label: item.title,
                                  value: item.album_id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "发售价格：", required: "" } },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "multipleSelection",
                              staticClass: "table",
                              staticStyle: {
                                width: "100%",
                                "margin-top": "-10px",
                              },
                              attrs: {
                                "tooltip-effect": "dark",
                                data: _vm.priceList,
                                size: "mini",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { label: " ", "min-width": "60" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                  售价 " +
                                            _vm._s(scope.$index + 1) +
                                            "\n                "
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "价格名称",
                                  "min-width": "100",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder: "请输入价格名称",
                                          },
                                          model: {
                                            value: scope.row.price_name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "price_name",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.price_name",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "价格",
                                  "min-width": "100",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-input-number",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: { step: 1 },
                                            model: {
                                              value: scope.row.price,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "price",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.price",
                                            },
                                          },
                                          [_vm._v("\n                  >")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "开盒次数",
                                  "min-width": "100",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-input-number",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              min: 1,
                                              step: 1,
                                              disabled: scope.$index == 0,
                                            },
                                            model: {
                                              value: scope.row.box_count,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "box_count",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.box_count",
                                            },
                                          },
                                          [_vm._v("\n                  >")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  "min-width": "80",
                                  fixed: "right",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.$index > 0
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delPrice(
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      删除\n                    "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.priceList.length < 4
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.addPrice },
                                },
                                [_vm._v("增加售价")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "选择级别", required: "" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              on: { change: _vm.handleCheckedLevelChange },
                              model: {
                                value: _vm.checkedLevel,
                                callback: function ($$v) {
                                  _vm.checkedLevel = $$v
                                },
                                expression: "checkedLevel",
                              },
                            },
                            _vm._l(_vm.levelList, function (item) {
                              return _c(
                                "el-checkbox",
                                { key: item.id, attrs: { label: item.id } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.level_name) +
                                      "\n              "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "概率设置", required: "" } },
                        _vm._l(_vm.boxConsigneeData, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: "boxc_" + index,
                              staticStyle: { "margin-bottom": "20px" },
                            },
                            [
                              _vm._v(
                                "\n               " +
                                  _vm._s(item.level_name) +
                                  "\n               "
                              ),
                              _c("el-input-number", {
                                staticStyle: { width: "70px" },
                                attrs: { controls: false },
                                model: {
                                  value: item.consignee_before,
                                  callback: function ($$v) {
                                    _vm.$set(item, "consignee_before", $$v)
                                  },
                                  expression: "item.consignee_before",
                                },
                              }),
                              _vm._v("% -\n               "),
                              _c("el-input-number", {
                                staticStyle: { width: "70px" },
                                attrs: {
                                  controls: false,
                                  disabled: index == 0,
                                },
                                on: { input: _vm.changeafterc },
                                model: {
                                  value: item.consignee_after,
                                  callback: function ($$v) {
                                    _vm.$set(item, "consignee_after", $$v)
                                  },
                                  expression: "item.consignee_after",
                                },
                              }),
                              _vm._v("%\n               "),
                              _c("span", {
                                staticStyle: { "margin-left": "40px" },
                              }),
                              _vm._v(" "),
                              index > 0
                                ? [
                                    _vm._v("\n                 当销量每达到"),
                                    _c("el-input-number", {
                                      staticStyle: { width: "70px" },
                                      attrs: { controls: false },
                                      model: {
                                        value: item.need_buy_number,
                                        callback: function ($$v) {
                                          _vm.$set(item, "need_buy_number", $$v)
                                        },
                                        expression: "item.need_buy_number",
                                      },
                                    }),
                                    _vm._v("个时，可释放1个\n               "),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm.formValidate.leftimage &&
                    _vm.formValidate.rightimage &&
                    _vm.formValidate.topimage
                      ? _c("div", { staticClass: "box" }, [
                          _c("div", { staticClass: "layer" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.formValidate.leftimage + "!120a",
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "layer" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.formValidate.rightimage + "!120a",
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "layer" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.formValidate.rightimage + "!120a",
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "layer" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.formValidate.leftimage + "!120a",
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "layer" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.formValidate.topimage + "!120a",
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "layer" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.formValidate.topimage + "!120a",
                                alt: "",
                              },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "概率说明：" } },
                        [
                          _c("Tinymce", {
                            ref: "editor",
                            attrs: { height: 300 },
                            model: {
                              value: _vm.formValidate.consignee_content,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "consignee_content",
                                  $$v
                                )
                              },
                              expression: "formValidate.consignee_content",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品设置", required: "" } },
                        [
                          _c(
                            "el-tabs",
                            {
                              on: { "tab-click": _vm.selectLevelProduct },
                              model: {
                                value: _vm.type,
                                callback: function ($$v) {
                                  _vm.type = $$v
                                },
                                expression: "type",
                              },
                            },
                            _vm._l(_vm.levelProduct, function (item, index) {
                              return _c("el-tab-pane", {
                                key: index,
                                attrs: {
                                  name: index.toString(),
                                  label:
                                    item.level_name + "(" + item.count + ")",
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: " " } },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "multipleSelection",
                              staticClass: "table",
                              staticStyle: {
                                width: "100%",
                                "margin-top": "-10px",
                              },
                              attrs: {
                                "tooltip-effect": "dark",
                                "row-key": function (row) {
                                  return row.product_id
                                },
                                data: _vm.orderArr,
                                size: "mini",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { width: "80", label: "图片" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "demo-image__preview",
                                          },
                                          [
                                            _c("el-image", {
                                              attrs: {
                                                src: scope.row.image + "!120a",
                                                "preview-src-list": [
                                                  scope.row.image,
                                                ],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "名称",
                                  "min-width": "100",
                                  prop: "store_name",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "隐藏款", "min-width": "120" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-value": "1",
                                                "inactive-value": "0",
                                                "active-text": "是",
                                                "inactive-text": "否",
                                              },
                                              model: {
                                                value: scope.row.is_hide,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "is_hide",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.is_hide",
                                              },
                                            }),
                                            _vm._v(" "),
                                            scope.row.is_hide == 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "upLoadPicBox",
                                                    staticStyle: {
                                                      width: "60px",
                                                      height: "60px",
                                                    },
                                                    attrs: {
                                                      title: "正方形图片",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.modalPicTap(
                                                          "1",
                                                          "product",
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    scope.row.hide_image
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "pictrue",
                                                            staticStyle: {
                                                              width: "60px",
                                                              height: "60px",
                                                            },
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src:
                                                                  scope.row
                                                                    .hide_image +
                                                                  "!120a",
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "upLoad",
                                                            staticStyle: {
                                                              width: "60px",
                                                              height: "60px",
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-camera cameraIconfont",
                                                            }),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v("封面图"),
                                                            ]),
                                                          ]
                                                        ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "issale",
                                  label: "只在盲盒售卖",
                                  "min-width": "90",
                                  "render-header": _vm.atteLeavetooltip,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-switch", {
                                          attrs: {
                                            "active-value": "1",
                                            "inactive-value": "0",
                                            "active-text": "是",
                                            "inactive-text": "否",
                                          },
                                          model: {
                                            value: scope.row.issale,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "issale", $$v)
                                            },
                                            expression: "scope.row.issale",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "stock",
                                  label: "当前库存",
                                  "min-width": "100",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "投放数量",
                                  "min-width": "100",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input-number", {
                                          staticStyle: { width: "150px" },
                                          attrs: {
                                            min: 1,
                                            step: 1,
                                            max: scope.row.stock,
                                          },
                                          model: {
                                            value: scope.row.issuer_num,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "issuer_num",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.issuer_num",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  "min-width": "80",
                                  fixed: "right",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          [
                                            _vm.orderidArr.indexOf(
                                              scope.row.product_id
                                            ) > -1
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.cancelSelect(
                                                          scope.$index,
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      删除\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm("formValidate")
                                },
                              },
                            },
                            [_vm._v("提交")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tabs",
            { attrs: { value: "goods" } },
            [_c("el-tab-pane", { attrs: { label: "商品", name: "goods" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "关键字搜索：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "250px" },
                          attrs: { placeholder: "请输入商品名称，关键字" },
                          model: {
                            value: _vm.queryParams.keyword,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "keyword", $$v)
                            },
                            expression: "queryParams.keyword",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData.data, size: "mini" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "product_id",
                      label: "ID",
                      "min-width": "50",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "商品图", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "demo-image__preview" },
                              [
                                _c("el-image", {
                                  attrs: {
                                    src: scope.row.image,
                                    "preview-src-list": [scope.row.image],
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "store_name",
                      label: "商品名称",
                      "min-width": "200",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "price",
                      label: "商品售价",
                      "min-width": "90",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "sales", label: "销量", "min-width": "90" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "stock", label: "库存", "min-width": "90" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "sort", label: "排序", "min-width": "70" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_time",
                      label: "创建时间",
                      "min-width": "150",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      "min-width": "150",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.stock > 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectinver(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.orderidArr.indexOf(
                                            scope.row.product_id
                                          ) > -1
                                            ? "已选择商品"
                                            : "选择商品"
                                        ) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20 flex justify-e" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-size": _vm.queryParams.limit,
                      "current-page": _vm.queryParams.page,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.tableData.total,
                    },
                    on: { "current-change": _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }