"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _product = require("@/api/product");
var _systemForm = require("@/api/systemForm");
var _video = require("@/api/video");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Firstbuy',
  data: function data() {
    return {
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      detail: {},
      tableFrom: {
        pageNum: 1,
        pageSize: 80,
        businessScenario: '',
        contentLatitudeId: '',
        contentLocalizationId: '',
        workShapeId: '',
        firstCategoryId: '',
        secondaryCategoryId: '',
        ratio: ''
      },
      rules: {},
      roterPre: _settings.roterPre,
      showVideo: false,
      videoRatio: '16:9',
      videoUrl: '',
      types: [],
      typeValues: [],
      categores: [],
      categoresVals: [],
      categoryList: [],
      categoryTwoList: [],
      tagList: [],
      attrList: [],
      categoryIds: [],
      xingzhuang: {},
      bili: {},
      dingwei: {},
      weidu: {},
      tagIdList: [],
      categoryJSON: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _product.storeCategoryListApi)({
      type: 2
    }).then(function (res) {
      _this.categoryList = res.data;
      var catejson = {};
      res.data.forEach(function (m) {
        if (m.children && m.children.length > 0) {
          m.children.forEach(function (n) {
            catejson[n.id] = m.name + '/' + n.name;
          });
        } else {
          catejson[m.id] = m.name;
        }
      });
      _this.categoryJSON = catejson;
      if (_this.id) {
        _this.load();
      }
    });
    (0, _systemForm.labelLstApi)({
      type: 2
    }).then(function (res) {
      _this.tagList = res.rows;
    });
    (0, _product.attrListApi)({
      useType: 3
    }).then(function (res) {
      _this.attrList = res.rows;
      res.rows.forEach(function (m) {
        if (m.attrName === '作品形状') {
          _this.xingzhuang = Object.assign({}, m);
        } else if (m.attrName === '画面比例') {
          _this.bili = Object.assign({}, m);
        } else if (m.attrName === '内容定义') {
          _this.dingwei = Object.assign({}, m);
        } else if (m.attrName === '内容类型') {
          _this.weidu = Object.assign({}, m);
        }
      });
    });
    this.getList();
  },
  created: function created() {
    var _this2 = this;
    (0, _video.getVideoPurposeListAPI)({
      type: 1
    }).then(function (res) {
      _this2.types = res.data;
      _this2.typeValues = res.data.map(function (m) {
        return m.value;
      });
    });
    (0, _video.getVideoTypeListAPI)({
      type: 1
    }).then(function (res) {
      _this2.categores = res.data;
      _this2.categoresVals = res.data.map(function (m) {
        return m.value;
      });
    });
  },
  methods: {
    previewVideo: function previewVideo(item) {
      this.videoRatio = item.ratio;
      this.videoUrl = item.previewVideoUrl;
      this.showVideo = true;
    },
    // 列表
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.tableFrom.pageNum = num || this.tableFrom.pageNum;
      this.tableFrom.tagIds = this.tagIdList.join(',');
      (0, _video.getVideoComponentListAPI)(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.rows;
        _this3.tableData.total = res.total;
        _this3.listLoading = false;
      }).catch(function (res) {
        _this3.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    // 添加
    onAdd: function onAdd() {
      this.detail = {
        name: '',
        purpose: '',
        templateType: '',
        firstCategoryId: '',
        secondaryCategoryId: '',
        contentLatitudeId: '',
        contentLocalizationId: '',
        workShapeId: '',
        tagIds: [],
        isOpenDubbing: false,
        isDynamicBackgroundColor: false,
        hasGroupingCycle: false,
        isSpecifyGrouping: false,
        show: true,
        btnLoading: false
      };
    },
    editAction: function editAction(item) {
      this.categoryIds = item.categoryIds;
      this.detail = {
        id: item.id,
        name: item.name,
        purpose: item.purpose,
        templateType: item.templateType,
        isOpenDubbing: item.isOpenDubbing,
        isDynamicBackgroundColor: item.isDynamicBackgroundColor,
        hasGroupingCycle: item.hasGroupingCycle,
        isSpecifyGrouping: item.isSpecifyGrouping,
        firstCategoryId: item.firstCategoryId,
        secondaryCategoryId: item.secondaryCategoryId,
        contentLatitudeId: item.contentLatitudeId,
        contentLocalizationId: item.contentLocalizationId,
        workShapeId: item.workShapeId,
        tagIds: item.tagIds ? JSON.parse(item.tagIds) : [],
        show: true,
        btnLoading: false
      };
      this.changeTwoCategory();
    },
    setAction: function setAction(id) {
      window.open("/merchant/video/assemblyDetail?id=".concat(id));
    },
    // 删除
    handleDelete: function handleDelete(id) {
      var _this4 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u6B64\u7EC4\u4EF6?", '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _video.videoComponentDelAPI)(id).then(function (_ref) {
          var message = _ref.message;
          _this4.$message.success('操作成功');
          _this4.getList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
        });
      }).catch(function () {});
    },
    // 删除
    copyAction: function copyAction(id) {
      var _this5 = this;
      (0, _video.getVideoComponentCopyAPI)(id).then(function (_ref3) {
        var message = _ref3.message;
        _this5.$message.success('操作成功');
        _this5.getList();
      }).catch(function (_ref4) {
        var message = _ref4.message;
      });
    },
    reduceCatearr: function reduceCatearr(catearr) {
      var nestedArray = catearr;
      var flatArray = nestedArray.reduce(function (acc, val) {
        return acc.concat(val);
      }, []);
      var uniqueObj = {};
      var uniqueArray = flatArray.filter(function (item) {
        if (!uniqueObj[item]) {
          uniqueObj[item] = true;
          return true;
        }
        return false;
      });
      return uniqueArray;
    },
    confirmAction: function confirmAction() {
      var _this6 = this;
      if (!this.detail.name) {
        this.$message.error('请输入组件名称');
        return false;
      }
      if (!this.detail.templateType) {
        this.$message.error('请选择组件类型');
        return false;
      }
      // if (!this.detail.purpose) {
      //   this.$message.error('请选择组件用途');
      //   return false;
      // }

      this.detail.btnLoading = true;
      this.detail.categoryIds = this.reduceCatearr(this.categoryIds);
      (0, _video.videoComponentEditAPI)(this.detail).then(function () {
        _this6.$message.success('操作成功');
        _this6.detail.btnLoading = false;
        _this6.detail.show = false;
        _this6.getList(1);
      });
    },
    changeTwoCategory: function changeTwoCategory() {
      var ids = this.categoryList.map(function (m) {
        return m.id;
      });
      var index = ids.indexOf(this.detail.firstCategoryId);
      if (index !== -1) {
        this.categoryTwoList = this.categoryList[index].children;
      }
    },
    changeTwoCategorySearch: function changeTwoCategorySearch() {
      var ids = this.categoryList.map(function (m) {
        return m.id;
      });
      var index = ids.indexOf(this.tableFrom.firstCategoryId);
      if (index !== -1) {
        this.categoryTwoList = this.categoryList[index].children;
      } else {
        this.categoryTwoList = '';
        this.tableFrom.secondaryCategoryId = '';
      }
      this.getList(1);
    }
  }
};