"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTagListApi = getTagListApi;
exports.getTypeListApi = getTypeListApi;
var _requestPoster = _interopRequireDefault(require("./requestPoster"));
/**
 * 
 */
function getTypeListApi(data) {
  return _requestPoster.default.get('/api/template/typeList', data);
}
/**
 * 
 */
function getTagListApi(data) {
  return _requestPoster.default.get('/api/tag/list', data);
}