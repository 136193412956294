var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "set-content", staticStyle: { height: "100%" } },
    [
      _c(
        "el-scrollbar",
        { staticStyle: { height: "100%" } },
        [
          _c("div", { staticClass: "item-align-center mt20" }, [
            _c("div", { staticClass: "fontW400 ft14 color-black" }, [
              _vm._v("图片展示方式"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-align-center banner-control",
              staticStyle: { "margin-top": "15px" },
            },
            _vm._l(_vm.styleData, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "change-type mr20 ft12" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "img",
                      class: { curr: item.value === _vm.facade.style },
                      on: {
                        click: function ($event) {
                          return _vm.setStyle(item.value)
                        },
                      },
                    },
                    [_c("label", [_c("img", { attrs: { src: item.src } })])]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "name color-gray align-c" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "item-align-center mt20" }, [
            _c("div", { staticClass: "fontW400 ft14 color-black" }, [
              _vm._v("图片展示方式"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-align-center flex-wrap" },
            _vm._l(_vm.imgStyleData, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "change-type",
                  staticStyle: { "margin-right": "20px", "margin-top": "15px" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "img",
                      class: { curr: item.value === _vm.facade.img_style },
                    },
                    [
                      _c("label", [
                        _c("img", { attrs: { src: item.src } }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.facade.img_style,
                              expression: "facade.img_style",
                            },
                          ],
                          staticClass: "choose-input",
                          attrs: { type: "radio", name: "choose" },
                          domProps: {
                            value: item.value,
                            checked: _vm._q(_vm.facade.img_style, item.value),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.facade,
                                "img_style",
                                item.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "name color-gray align-c ft12 mt5" },
                    [_vm._v(_vm._s(item.name))]
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "h30" }),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.background_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "background_color", $$v)
                      },
                      expression: "facade.background_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "图上圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.img_radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "img_radius", $$v)
                      },
                      expression: "facade.img_radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "图下圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.img_bottom_radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "img_bottom_radius", $$v)
                      },
                      expression: "facade.img_bottom_radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.facade.style !== 1
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "图片间距" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 375 },
                        model: {
                          value: _vm.facade.item_padding,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "item_padding", $$v)
                          },
                          expression: "facade.item_padding",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("choose", {
                staticClass: "mt20",
                attrs: {
                  data: _vm.pointerData,
                  title: "指示器类型",
                  margin: "0 10px 0 0",
                  width: "75px",
                  height: "30px",
                },
                model: {
                  value: _vm.facade.indicator_style,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "indicator_style", $$v)
                  },
                  expression: "facade.indicator_style",
                },
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "指示器对齐" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.facade.indicator_vertical_align,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "indicator_vertical_align", $$v)
                        },
                        expression: "facade.indicator_vertical_align",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "top" } }, [
                        _vm._v("浮动图片上"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "bottom" } }, [
                        _vm._v("单独占一行"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "指示器位置" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.facade.indicator_align,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "indicator_align", $$v)
                        },
                        expression: "facade.indicator_align",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "left" } }, [
                        _vm._v("居左"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "center" } }, [
                        _vm._v("居中"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "right" } }, [
                        _vm._v("居右"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "选中颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.indicator_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "indicator_color", $$v)
                      },
                      expression: "facade.indicator_color",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }