var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "filter-container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        inline: "",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "时间选择：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: { type: "button", size: "small" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectChange(_vm.tableFrom.date)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "date", $$v)
                                },
                                expression: "tableFrom.date",
                              },
                            },
                            _vm._l(_vm.fromList.fromTxt, function (item, i) {
                              return _c(
                                "el-radio-button",
                                { key: i, attrs: { label: item.val } },
                                [_vm._v(_vm._s(item.text))]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              "value-format": "yyyy/MM/dd",
                              format: "yyyy/MM/dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              placeholder: "自定义时间",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "优惠券类型：" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth mr20",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.coupon_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "coupon_type", $$v)
                                },
                                expression: "tableFrom.coupon_type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "店铺券", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "商品券", value: 1 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "优惠券名称：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth mr20",
                              attrs: {
                                placeholder: "请输入优惠券名称",
                                clearable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.coupon_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "coupon_name", $$v)
                                },
                                expression: "tableFrom.coupon_name",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "coupon_send_id",
                  label: "ID",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "title",
                  label: "优惠劵名称",
                  "min-width": "200",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "优惠劵类型", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("couponTypeFilter")(row.type))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  "min-width": "200",
                  label: "发送日期",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "200", label: "使用有效期" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.use_start_time && row.use_end_time
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(row.use_start_time) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                "- " + _vm._s(row.use_end_time) + "\n          "
                              ),
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(row.coupon_time) + "天"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "200", label: "筛选条件" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        !row.mark.search
                          ? _c("span", [_vm._v("无")])
                          : _c("div", [
                              row.mark.search["用户标签"]
                                ? _c("span", [
                                    _vm._v(
                                      "\n                用户标签:" +
                                        _vm._s(row.mark.search["用户标签"]) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.mark.search["用户类型"]
                                ? _c("span", [
                                    _vm._v(
                                      "\n                用户类型:" +
                                        _vm._s(row.mark.search["用户类型"]) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.mark.search["性别"]
                                ? _c("span", [
                                    _vm._v(
                                      "\n                性别:" +
                                        _vm._s(row.mark.search["性别"]) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.mark.search["消费情况"]
                                ? _c("span", [
                                    _vm._v(
                                      "\n                消费情况:" +
                                        _vm._s(row.mark.search["消费情况"]) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.mark.search["身份"]
                                ? _c("span", [
                                    _vm._v(
                                      "\n                身份:" +
                                        _vm._s(row.mark.search["身份"]) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.mark.search["访问情况"]
                                ? _c("span", [
                                    _vm._v(
                                      "\n                访问情况:" +
                                        _vm._s(row.mark.search["访问情况"]) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.mark.search["访问时间"]
                                ? _c("span", [
                                    _vm._v(
                                      "\n                访问时间:" +
                                        _vm._s(row.mark.search["访问时间"]) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.mark.search["昵称"]
                                ? _c("span", [
                                    _vm._v(
                                      "\n                昵称:" +
                                        _vm._s(row.mark.search["昵称"]) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "200", label: "使用情况" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _c("span", [
                            _vm._v("发放数量：" + _vm._s(row.coupon_num)),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "sheng" }, [
                            _vm._v("发放使用数量：" + _vm._s(row.useCount)),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "150", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.details(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.usedRecord(scope.row.coupon_send_id)
                              },
                            },
                          },
                          [_vm._v("使用记录")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.detailDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "优惠券详情",
                visible: _vm.detailDialog,
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.detailDialog = $event
                },
              },
            },
            [
              _c("div", [
                _c("div", { staticClass: "box-container" }, [
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("优惠券名称："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.couponDetail.title)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("优惠券类型："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("couponTypeFilter")(_vm.couponDetail.type)
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("优惠券面值："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.couponDetail.coupon_price)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("使用门槛："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(
                        _vm._s(
                          _vm.couponDetail.use_min_price == "0.00"
                            ? "无门槛"
                            : "最低消费" + _vm.couponDetail.use_min_price
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("使用有效期："),
                    ]),
                    _vm._v(" "),
                    _vm.couponDetail.coupon_time &&
                    _vm.couponDetail.coupon_type == 0
                      ? _c("span", { staticClass: "info" }, [
                          _vm._v(_vm._s(_vm.couponDetail.coupon_time) + "天"),
                        ])
                      : _vm.couponDetail.coupon_type == 1
                      ? _c(
                          "span",
                          {
                            staticClass: "info",
                            staticStyle: { "font-size": "12px" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.couponDetail.use_start_time +
                                  " - " +
                                  _vm.couponDetail.use_end_time
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("领取时间："),
                    ]),
                    _vm._v(" "),
                    _vm.couponDetail.is_timeout == 1
                      ? _c("span", { staticClass: "info" }, [
                          _vm._v(
                            _vm._s(_vm.couponDetail.start_time) +
                              " - " +
                              _vm._s(_vm.couponDetail.end_time)
                          ),
                        ])
                      : _c(
                          "span",
                          {
                            staticClass: "info",
                            staticStyle: { "font-size": "12px" },
                          },
                          [_vm._v("不限时")]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [_vm._v("类型：")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("couponUseTypeFilter")(
                            _vm.couponDetail.send_type
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("是否限量："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("filterClose")(_vm.couponDetail.is_limited)
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("已发布总数："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(
                        _vm._s(
                          _vm.couponDetail.is_limited == 0
                            ? "不限量"
                            : _vm.couponDetail.total_count
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("剩余总数："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(
                        _vm._s(
                          _vm.couponDetail.is_limited == 0
                            ? "不限量"
                            : _vm.couponDetail.remain_count
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("已发送总数："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.couponDetail.coupon_num)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("已使用总数："),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "info", staticStyle: { color: "red" } },
                      [_vm._v(_vm._s(_vm.couponDetail.useCount))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("发放筛选条件："),
                    ]),
                    _vm._v(" "),
                    !_vm.couponDetail.mark.search
                      ? _c("span", [_vm._v("无")])
                      : _c("span", [
                          _vm.couponDetail.mark.search["用户标签"]
                            ? _c("span", { staticClass: "condition" }, [
                                _vm._v(
                                  "\n                用户标签:" +
                                    _vm._s(
                                      _vm.couponDetail.mark.search["用户标签"]
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.couponDetail.mark.search["用户类型"]
                            ? _c("span", { staticClass: "condition" }, [
                                _vm._v(
                                  "\n                用户类型:" +
                                    _vm._s(
                                      _vm.couponDetail.mark.search["用户类型"]
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.couponDetail.mark.search["性别"]
                            ? _c("span", { staticClass: "condition" }, [
                                _vm._v(
                                  "\n                性别:" +
                                    _vm._s(
                                      _vm.couponDetail.mark.search["性别"]
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.couponDetail.mark.search["消费情况"]
                            ? _c("span", { staticClass: "condition" }, [
                                _vm._v(
                                  "\n                消费情况:" +
                                    _vm._s(
                                      _vm.couponDetail.mark.search["消费情况"]
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.couponDetail.mark.search["身份"]
                            ? _c("span", { staticClass: "condition" }, [
                                _vm._v(
                                  "\n                身份:" +
                                    _vm._s(
                                      _vm.couponDetail.mark.search["身份"]
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.couponDetail.mark.search["访问情况"]
                            ? _c("span", { staticClass: "condition" }, [
                                _vm._v(
                                  "\n                访问情况:" +
                                    _vm._s(
                                      _vm.couponDetail.mark.search["访问情况"]
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.couponDetail.mark.search["访问时间"]
                            ? _c("span", { staticClass: "condition" }, [
                                _vm._v(
                                  "\n                访问时间:" +
                                    _vm._s(
                                      _vm.couponDetail.mark.search["访问时间"]
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.couponDetail.mark.search["昵称"]
                            ? _c("span", { staticClass: "condition" }, [
                                _vm._v(
                                  "\n                昵称:" +
                                    _vm._s(
                                      _vm.couponDetail.mark.search["昵称"]
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [_vm._v("排序：")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.couponDetail.sort)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list sp" }, [
                    _c("label", { staticClass: "name" }, [_vm._v("状态：")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.couponDetail.status ? "开启" : "关闭")),
                    ]),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "modalbox",
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            "min-width": "500px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "header clearfix" }, [
            _c("div", { staticClass: "filter-container" }, [
              _c(
                "div",
                { staticClass: "demo-input-suffix acea-row" },
                [
                  _c("span", { staticClass: "seachTiele" }, [
                    _vm._v("优惠券获取方式："),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item selWidth mr20",
                      attrs: { placeholder: "请选择", clearable: "" },
                      on: {
                        change: function ($event) {
                          return _vm.getIssueList()
                        },
                      },
                      model: {
                        value: _vm.tableFromIssue.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFromIssue, "type", $$v)
                        },
                        expression: "tableFromIssue.type",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "全部", value: "" } }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "自己领取", value: "receive" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "后台发送", value: "send" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "新人赠送", value: "new" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "买东西赠送", value: "buy" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.Loading,
                  expression: "Loading",
                },
              ],
              staticStyle: { width: "100%", "margin-top": "15px" },
              attrs: {
                data: _vm.issueData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "user.nickname",
                  label: "用户名",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户头像", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.user.avatar
                          ? _c("div", { staticClass: "demo-image__preview" }, [
                              _c("img", {
                                staticStyle: { width: "36px", height: "36px" },
                                attrs: { src: scope.row.user.avatar },
                              }),
                            ])
                          : _c("div", { staticClass: "demo-image__preview" }, [
                              _c("img", {
                                staticStyle: { width: "36px", height: "36px" },
                                attrs: {
                                  src: require("../../../../assets/images/f.png"),
                                },
                              }),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "200", label: "优惠券获取方式" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.type == "receive"
                                ? "自己领取"
                                : scope.row.type == "send"
                                ? "后台发送"
                                : scope.row.type == "new"
                                ? "新人"
                                : "买赠送"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "使用情况", "min-width": "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.use_time
                          ? _c("span", [_vm._v(_vm._s(scope.row.use_time))])
                          : _c("span", [_vm._v("未使用")]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.tableFromIssue.limit,
                  "current-page": _vm.tableFromIssue.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.issueData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChangeIssue,
                  "current-change": _vm.pageChangeIssue,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }