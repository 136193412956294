"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Page404',
  computed: {
    message: function message() {
      return '你不能进入这个页面...';
    }
  },
  data: function data() {
    return {
      roterPre: _settings.roterPre
    };
  }
};