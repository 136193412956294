"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _index = require("@/utils/index");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 100,
        order: 1
      },
      receiveData: {
        show: false,
        user: null,
        invite_num: 0,
        invite_buy_num: 0
      },
      changeType: '1'
    };
  },
  mounted: function mounted() {
    if (this.$route.query.id) {
      this.tableFrom.help_invite_id = this.$route.query.id;
      this.getList(1);
    }
  },
  methods: {
    // 列表
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _marketing.helpRankingApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    editOrderAction: function editOrderAction(val) {
      this.tableFrom.order = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    adjustAction: function adjustAction(m) {
      this.receiveData = {
        show: true,
        id: m.id,
        user: m.user,
        invite_num: m.invite_num,
        invite_buy_num: m.invite_buy_num
      };
    },
    saveAdjustAction: function saveAdjustAction() {
      var _this2 = this;
      this.$confirm("\u786E\u8BA4\u4FEE\u6539 ".concat(this.receiveData.user && this.receiveData.user.nickname ? this.receiveData.user.nickname : '', " \u7684\u9080\u8BF7\u4EBA\u6570\u4E3A ").concat(this.receiveData.invite_num, " ;\u8D2D\u4E70\u6B21\u6570\u4E3A ").concat(this.receiveData.invite_buy_num, " ?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.editHelpInviteNumApi)({
          task_id: _this2.receiveData.id,
          invite_num: _this2.receiveData.invite_num,
          invite_buy_num: _this2.receiveData.invite_buy_num
        }).then(function () {
          _this2.$message.success('操作成功');
          _this2.receiveData.show = false;
          _this2.getList('');
        }).catch(function (message) {});
      }).catch(function () {});
    }
  }
};