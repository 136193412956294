var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100vw", height: "100vh", overflow: "hidden" } },
    [
      _c("div", { attrs: { id: "main" } }, [
        _c("div", { staticClass: "demo" }, [
          _c(
            "div",
            {
              ref: "toImg",
              staticClass: "grid",
              style: "width:1920px;height:1080px;",
            },
            [
              _vm._l(_vm.imgList, function (item, index) {
                return [
                  _c("img", {
                    key: index,
                    style: "width:213.3px;height:216px;",
                    attrs: { src: item, alt: "", crossorigin: "anonymous" },
                  }),
                ]
              }),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "animate" }, [_vm._v("点击看效果")]),
      _vm._v(" "),
      _c("img", {
        staticStyle: { display: "none" },
        attrs: { id: "converted-image" },
      }),
      _vm._v(" "),
      _vm.blob
        ? _c("video", { attrs: { src: _vm.blob, controls: "" } })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }