var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card FromData" },
        [
          _vm.FromData
            ? _c("form-create", {
                attrs: { option: _vm.option, rule: _vm.FromData.rule },
                on: { "on-submit": _vm.onSubmit },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }