var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "video-content",
      class: {
        "video-content-sdk": _vm.aspectRatio == "9:16",
        "video-content-sdk video-content-sdk34": _vm.aspectRatio == "3:4",
      },
    },
    [
      _c("div", {
        ref: "containerRef",
        staticStyle: { height: "100%", width: "100%" },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            title: "设置组件信息",
            "append-to-body": "",
            width: "1800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("div", { staticClass: "item-view" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "padding-top": "20px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "type",
                        staticStyle: { "font-weight": "bold" },
                      },
                      [_vm._v("类型")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "type",
                        staticStyle: {
                          width: "100px",
                          "line-height": "20px",
                          "margin-left": "20px",
                          "font-weight": "500",
                        },
                      },
                      [_vm._v("内容")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "type",
                        staticStyle: {
                          width: "150px",
                          "line-height": "20px",
                          "margin-left": "20px",
                          "font-weight": "500",
                        },
                      },
                      [_vm._v("选择类型")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "type",
                        staticStyle: {
                          width: "670px",
                          "line-height": "20px",
                          "margin-left": "20px",
                          "font-weight": "500",
                        },
                      },
                      [_vm._v("类型相关设置")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "type",
                        staticStyle: {
                          width: "320px",
                          "line-height": "20px",
                          "margin-left": "20px",
                          "font-weight": "500",
                        },
                      },
                      [_vm._v("是否随机动画")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "type",
                        staticStyle: {
                          width: "80px",
                          "line-height": "20px",
                          "margin-left": "20px",
                          "font-weight": "500",
                        },
                      },
                      [_vm._v("颜色分组")]
                    ),
                    _vm._v(" "),
                    _vm.hasGroupingCycle
                      ? _c(
                          "div",
                          {
                            staticClass: "type",
                            staticStyle: {
                              width: "240px",
                              "line-height": "20px",
                              "margin-left": "20px",
                              "font-weight": "500",
                            },
                          },
                          [_vm._v("循环设置")]
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.saveTimeline.VideoTracks, function (m, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "item-view" },
                  _vm._l(m.VideoTrackClips, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: "" + i + index,
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "padding-top": "20px",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "type",
                            staticStyle: { "font-weight": "bold" },
                          },
                          [
                            item.Type === "Image" ? [_vm._v("图片")] : _vm._e(),
                            _vm._v(" "),
                            item.Type === "Video" ? [_vm._v("视频")] : _vm._e(),
                            _vm._v(" "),
                            item.Type === "Text" ? [_vm._v("文字")] : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        item.Type === "Image" || item.Type === "Video"
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100px",
                                  height: "50px",
                                  "margin-left": "20px",
                                },
                              },
                              [
                                item.Src
                                  ? _c("el-image", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                      attrs: { src: item.Src, fit: "contain" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.Type === "Text"
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100px",
                                  height: "50px",
                                  "line-height": "50px",
                                  "margin-left": "20px",
                                  "white-space": "nowrap",
                                  "text-overflow": "ellipsis",
                                  overflow: "hidden",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.Content) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "select",
                            staticStyle: {
                              width: "150px",
                              "margin-left": "20px",
                            },
                          },
                          [
                            _c("el-cascader", {
                              attrs: {
                                placeholder: "类型 可不选",
                                options: _vm.options,
                                props: {
                                  expandTrigger: "hover",
                                  emitPath: false,
                                },
                                filterable: "",
                                clearable: "",
                              },
                              on: { change: _vm.setDis },
                              model: {
                                value: item.ContentType,
                                callback: function ($$v) {
                                  _vm.$set(item, "ContentType", $$v)
                                },
                                expression: "item.ContentType",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "select flex align-items-c",
                            staticStyle: {
                              width: "670px",
                              "margin-left": "20px",
                            },
                          },
                          [
                            (item.Type === "Image" || item.Type === "Video") &&
                            item.ContentType == "4002000"
                              ? [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "mr10",
                                      staticStyle: { width: "230px" },
                                      attrs: {
                                        placeholder: "形状类型",
                                        clearable: "",
                                        multiple: "",
                                      },
                                      model: {
                                        value: item.RatioType,
                                        callback: function ($$v) {
                                          _vm.$set(item, "RatioType", $$v)
                                        },
                                        expression: "item.RatioType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          value: "ordinary",
                                          label: "普通",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "preview",
                                          label: "预览",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { value: "two", label: "双图" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w100 mr10",
                                      attrs: {
                                        placeholder: "视频形状",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.Ratio,
                                        callback: function ($$v) {
                                          _vm.$set(item, "Ratio", $$v)
                                        },
                                        expression: "item.Ratio",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          value: "16:9",
                                          label: "横(16:9)",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "9:16",
                                          label: "竖(9:16)",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "3:4",
                                          label: "正(3:4)",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            (item.Type === "Image" || item.Type === "Video") &&
                            ("" + item.ContentType).indexOf("4031") !== -1
                              ? [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w100 mr10",
                                      attrs: {
                                        placeholder: "视频形状",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.Ratio,
                                        callback: function ($$v) {
                                          _vm.$set(item, "Ratio", $$v)
                                        },
                                        expression: "item.Ratio",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          value: "16:9",
                                          label: "横(16:9)",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "9:16",
                                          label: "竖(9:16)",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "3:4",
                                          label: "正(3:4)",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w100 mr10",
                                      attrs: {
                                        placeholder: "选择字体",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.CaptionsFontFamily,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item,
                                            "CaptionsFontFamily",
                                            $$v
                                          )
                                        },
                                        expression: "item.CaptionsFontFamily",
                                      },
                                    },
                                    _vm._l(
                                      _vm.fontList,
                                      function (fontM, fontI) {
                                        return _c("el-option", {
                                          key: fontI,
                                          attrs: {
                                            value: fontM.id,
                                            label: fontM.name,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("动态颜色：")]),
                                  _vm._v(" "),
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#13ce66",
                                      "inactive-color": "#ccc",
                                    },
                                    model: {
                                      value: item.CheckDynamicColor,
                                      callback: function ($$v) {
                                        _vm.$set(item, "CheckDynamicColor", $$v)
                                      },
                                      expression: "item.CheckDynamicColor",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "ml10" }, [
                                    _vm._v("显示版权："),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-switch", {
                                    staticClass: "mr10",
                                    attrs: {
                                      "active-color": "#13ce66",
                                      "inactive-color": "#ccc",
                                    },
                                    model: {
                                      value: item.IsShowCopyright,
                                      callback: function ($$v) {
                                        _vm.$set(item, "IsShowCopyright", $$v)
                                      },
                                      expression: "item.IsShowCopyright",
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            (item.Type === "Image" || item.Type === "Video") &&
                            ("" + item.ContentType).indexOf("4032") !== -1
                              ? [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w100 mr10",
                                      attrs: {
                                        placeholder: "视频形状",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.Ratio,
                                        callback: function ($$v) {
                                          _vm.$set(item, "Ratio", $$v)
                                        },
                                        expression: "item.Ratio",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          value: "16:9",
                                          label: "横(16:9)",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "9:16",
                                          label: "竖(9:16)",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "3:4",
                                          label: "正(3:4)",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            (item.Type === "Image" || item.Type === "Video") &&
                            (item.ContentType == "4041000" ||
                              item.ContentType == "3027000")
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "flex align-items-c" },
                                    [
                                      _c("span", [_vm._v("不显示二维码:")]),
                                      _vm._v(" "),
                                      _c("el-switch", {
                                        attrs: {
                                          "active-color": "#13ce66",
                                          "inactive-color": "#ccc",
                                        },
                                        model: {
                                          value: item.IsLogo,
                                          callback: function ($$v) {
                                            _vm.$set(item, "IsLogo", $$v)
                                          },
                                          expression: "item.IsLogo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex align-items-c",
                                      staticStyle: { "margin-left": "10px" },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "w100 mr10",
                                          attrs: {
                                            placeholder: "视频形状",
                                            clearable: "",
                                          },
                                          model: {
                                            value: item.Ratio,
                                            callback: function ($$v) {
                                              _vm.$set(item, "Ratio", $$v)
                                            },
                                            expression: "item.Ratio",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              value: "16:9",
                                              label: "横(16:9)",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              value: "9:16",
                                              label: "竖(9:16)",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              value: "3:4",
                                              label: "正(3:4)",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            (item.Type === "Image" || item.Type === "Video") &&
                            item.ContentType == "4001000"
                              ? [
                                  _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "flex align-items-c" },
                                      [
                                        _c("span", [_vm._v("开启动画：")]),
                                        _vm._v(" "),
                                        _c("el-switch", {
                                          attrs: {
                                            "active-color": "#13ce66",
                                            "inactive-color": "#ccc",
                                          },
                                          model: {
                                            value: item.IsOpenAnimation,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "IsOpenAnimation",
                                                $$v
                                              )
                                            },
                                            expression: "item.IsOpenAnimation",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "flex-one pl10 mr10" },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "动画效果",
                                                  multiple: "",
                                                  clearable: "",
                                                  disabled:
                                                    !item.IsOpenAnimation,
                                                },
                                                model: {
                                                  value: item.AnimationEffects,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "AnimationEffects",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.AnimationEffects",
                                                },
                                              },
                                              _vm._l(
                                                _vm.AnimationEffectsOptions,
                                                function (
                                                  optionVal,
                                                  optionIndex
                                                ) {
                                                  return _c("el-option", {
                                                    key: optionIndex,
                                                    attrs: {
                                                      label: optionVal.label,
                                                      value: optionVal.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex align-items-c mt10",
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "mr10",
                                            staticStyle: { width: "230px" },
                                            attrs: {
                                              placeholder: "形状类型",
                                              clearable: "",
                                              multiple: "",
                                            },
                                            model: {
                                              value: item.RatioType,
                                              callback: function ($$v) {
                                                _vm.$set(item, "RatioType", $$v)
                                              },
                                              expression: "item.RatioType",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                value: "ordinary",
                                                label: "普通",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                value: "preview",
                                                label: "预览",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                value: "two",
                                                label: "双图",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "w100 mr10",
                                            attrs: {
                                              placeholder: "视频形状",
                                              clearable: "",
                                            },
                                            model: {
                                              value: item.Ratio,
                                              callback: function ($$v) {
                                                _vm.$set(item, "Ratio", $$v)
                                              },
                                              expression: "item.Ratio",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                value: "16:9",
                                                label: "横(16:9)",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                value: "9:16",
                                                label: "竖(9:16)",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                value: "3:4",
                                                label: "正(3:4)",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                item.Type === "Image"
                                  ? [
                                      _c("span", [_vm._v("主色调：")]),
                                      _vm._v(" "),
                                      _c("el-switch", {
                                        attrs: {
                                          "active-color": "#13ce66",
                                          "inactive-color": "#ccc",
                                        },
                                        model: {
                                          value: item.MasterColor,
                                          callback: function ($$v) {
                                            _vm.$set(item, "MasterColor", $$v)
                                          },
                                          expression: "item.MasterColor",
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                (item.Type === "Image" ||
                                  item.Type === "Video") &&
                                (item.ContentType == "4001000" ||
                                  item.ContentType == "4019000" ||
                                  item.ContentType == "4002001" ||
                                  item.ContentType == "4002000")
                                  ? [
                                      _c("span", [_vm._v("动态时长：")]),
                                      _vm._v(" "),
                                      _c("el-switch", {
                                        staticClass: "mr10",
                                        attrs: {
                                          "active-color": "#13ce66",
                                          "inactive-color": "#ccc",
                                        },
                                        model: {
                                          value: item.IsDynamicDuration,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "IsDynamicDuration",
                                              $$v
                                            )
                                          },
                                          expression: "item.IsDynamicDuration",
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "margin-left": "10px" } },
                              [
                                item.Type === "Image" || item.Type === "Video"
                                  ? [
                                      _c("span", [_vm._v("主轴：")]),
                                      _vm._v(" "),
                                      _c("el-switch", {
                                        staticClass: "mr10",
                                        attrs: {
                                          "active-color": "#13ce66",
                                          "inactive-color": "#ccc",
                                        },
                                        model: {
                                          value: item.MasterTrack,
                                          callback: function ($$v) {
                                            _vm.$set(item, "MasterTrack", $$v)
                                          },
                                          expression: "item.MasterTrack",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("关闭解说时隐藏：")]),
                                      _vm._v(" "),
                                      _c("el-switch", {
                                        staticClass: "mr10",
                                        attrs: {
                                          "active-color": "#13ce66",
                                          "inactive-color": "#ccc",
                                        },
                                        model: {
                                          value: item.HideDuringCommentary,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "HideDuringCommentary",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.HideDuringCommentary",
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            item.Type === "Text"
                              ? [
                                  _c("span", [_vm._v("自动换行：")]),
                                  _vm._v(" "),
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#13ce66",
                                      "inactive-color": "#ccc",
                                      "active-value": "AutoWrap",
                                      "inactive-value": null,
                                    },
                                    on: {
                                      change: function (val) {
                                        return _vm.changeAdaptMode(
                                          val,
                                          i,
                                          index
                                        )
                                      },
                                    },
                                    model: {
                                      value: item.AdaptMode,
                                      callback: function ($$v) {
                                        _vm.$set(item, "AdaptMode", $$v)
                                      },
                                      expression: "item.AdaptMode",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "ml10" }, [
                                    _vm._v("竖版："),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#13ce66",
                                      "inactive-color": "#ccc",
                                      "active-value": 2,
                                      "inactive-value": null,
                                    },
                                    model: {
                                      value: item.TextType,
                                      callback: function ($$v) {
                                        _vm.$set(item, "TextType", $$v)
                                      },
                                      expression: "item.TextType",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-input-number", {
                                    staticClass: "ml10 w100",
                                    attrs: {
                                      controls: false,
                                      disabled: !item.AdaptMode,
                                      max: 100,
                                      min: 0,
                                      placeholder: "宽度设置",
                                    },
                                    model: {
                                      value: item.TextWidthSet,
                                      callback: function ($$v) {
                                        _vm.$set(item, "TextWidthSet", $$v)
                                      },
                                      expression: "item.TextWidthSet",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "ml5" }, [
                                    _vm._v("%"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-input-number", {
                                    staticClass: "ml10 w100",
                                    attrs: {
                                      controls: false,
                                      max: 10000,
                                      min: 0,
                                      placeholder: "最大字数",
                                    },
                                    model: {
                                      value: item.MaxFontCount,
                                      callback: function ($$v) {
                                        _vm.$set(item, "MaxFontCount", $$v)
                                      },
                                      expression: "item.MaxFontCount",
                                    },
                                  }),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "select flex align-items-c",
                            staticStyle: {
                              width: "320px",
                              "margin-left": "20px",
                            },
                          },
                          [
                            item.Type === "Text"
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "flex-one" },
                                    [
                                      item.Type === "Text"
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                placeholder: "分组 可不选",
                                              },
                                              model: {
                                                value: item.AnimationGroup,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "AnimationGroup",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.AnimationGroup",
                                              },
                                            },
                                            _vm._l(
                                              _vm.optionsNew,
                                              function (
                                                optionVal,
                                                optionIndex
                                              ) {
                                                return _c("el-option", {
                                                  key: optionIndex,
                                                  attrs: {
                                                    label: optionVal.label,
                                                    value: optionVal.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "ml20" },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            disabled: item.LoopAnimation,
                                          },
                                          on: {
                                            change: function (val) {
                                              return _vm.changeAnimation(
                                                val,
                                                i,
                                                index,
                                                "EnterAnimation"
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.EnterAnimation,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "EnterAnimation",
                                                $$v
                                              )
                                            },
                                            expression: "item.EnterAnimation",
                                          },
                                        },
                                        [_vm._v("入场")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            disabled: item.LoopAnimation,
                                          },
                                          on: {
                                            change: function (val) {
                                              return _vm.changeAnimation(
                                                val,
                                                i,
                                                index,
                                                "OutAnimation"
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.OutAnimation,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "OutAnimation",
                                                $$v
                                              )
                                            },
                                            expression: "item.OutAnimation",
                                          },
                                        },
                                        [_vm._v("出场")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            disabled:
                                              item.EnterAnimation ||
                                              item.OutAnimation,
                                          },
                                          on: {
                                            change: function (val) {
                                              return _vm.changeAnimation(
                                                val,
                                                i,
                                                index,
                                                "LoopAnimation"
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.LoopAnimation,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "LoopAnimation",
                                                $$v
                                              )
                                            },
                                            expression: "item.LoopAnimation",
                                          },
                                        },
                                        [_vm._v("循环")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : [
                                  _c(
                                    "el-checkbox",
                                    {
                                      model: {
                                        value: item.HasAnimation,
                                        callback: function ($$v) {
                                          _vm.$set(item, "HasAnimation", $$v)
                                        },
                                        expression: "item.HasAnimation",
                                      },
                                    },
                                    [_vm._v("特效")]
                                  ),
                                ],
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "select",
                            staticStyle: {
                              width: "80px",
                              "margin-left": "20px",
                            },
                          },
                          [
                            item.Type === "Text"
                              ? _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "分组 可不选",
                                      filterable: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: item.FontGroup,
                                      callback: function ($$v) {
                                        _vm.$set(item, "FontGroup", $$v)
                                      },
                                      expression: "item.FontGroup",
                                    },
                                  },
                                  _vm._l(
                                    _vm.colorGroupOption,
                                    function (optionVal, optionIndex) {
                                      return _c("el-option", {
                                        key: optionIndex,
                                        attrs: {
                                          label: optionVal.label,
                                          value: optionVal.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.hasGroupingCycle
                          ? _c(
                              "div",
                              {
                                staticClass: "select flex",
                                staticStyle: {
                                  width: "240px",
                                  "margin-left": "20px",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "110px" },
                                    attrs: {
                                      placeholder: "循环分组",
                                      filterable: "",
                                      clearable: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeCircular(
                                          i,
                                          index,
                                          "CircularNumber"
                                        )
                                      },
                                    },
                                    model: {
                                      value: item.CircularGrouping,
                                      callback: function ($$v) {
                                        _vm.$set(item, "CircularGrouping", $$v)
                                      },
                                      expression: "item.CircularGrouping",
                                    },
                                  },
                                  _vm._l(
                                    _vm.optionsGroup,
                                    function (optionVal, optionIndex) {
                                      return _c("el-option", {
                                        key: optionIndex,
                                        attrs: {
                                          label: optionVal.label,
                                          value: optionVal.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: {
                                      width: "110px",
                                      "margin-left": "20px",
                                    },
                                    attrs: {
                                      placeholder: "循环次数",
                                      filterable: "",
                                      clearable: "",
                                    },
                                    on: {
                                      change: function (val) {
                                        return _vm.changeCircularNumber(
                                          val,
                                          i,
                                          index
                                        )
                                      },
                                    },
                                    model: {
                                      value: item.CircularNumber,
                                      callback: function ($$v) {
                                        _vm.$set(item, "CircularNumber", $$v)
                                      },
                                      expression: "item.CircularNumber",
                                    },
                                  },
                                  _vm._l(10, function (optionVal, optionIndex) {
                                    return _c("el-option", {
                                      key: optionIndex,
                                      attrs: {
                                        label: optionVal + "次",
                                        value: optionVal,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn flex mt20" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.visible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.confirmAction },
                    },
                    [_vm._v("确定保存")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }