"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _review = require("@/api/review");
var _warehouse = require("@/api/warehouse");
var _settings = require("@/settings");
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "review",
  data: function data() {
    return {
      roterPre: _settings.roterPre,
      showconfig: false,
      logisticsName: "refund",
      id: 0,
      type: "",
      tableData: {
        data: [],
        total: 0
      },
      invoiceStatusList: [{
        label: "已开票",
        value: 1
      }, {
        label: "未开票",
        value: 0
      }],
      listLoading: true,
      tableFrom: {
        category: "transfers",
        username: "",
        type: "",
        date: "",
        page: 1,
        limit: 20,
        receipt_sn: "",
        order_type: "",
        keyword: "",
        status: ""
      },
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      fromList: {
        title: "选择时间",
        custom: true,
        fromTxt: [{
          text: "全部",
          val: ""
        }, {
          text: "今天",
          val: "today"
        }, {
          text: "昨天",
          val: "yesterday"
        }, {
          text: "最近7天",
          val: "lately7"
        }, {
          text: "最近30天",
          val: "lately30"
        }, {
          text: "本月",
          val: "month"
        }, {
          text: "本年",
          val: "year"
        }]
      },
      selectionList: [],
      tableFromLog: {
        page: 1,
        limit: 20
      },
      tableDataLog: {
        data: [],
        total: 0
      },
      loading: false,
      dialogVisible: false,
      orderDatalist: null,
      invoiceInfo: {},
      invoiceData: {
        number: "",
        mark: ""
      },
      inventoryDetailAll: {
        entrustOrderProduct: []
      },
      inventoryDetailFlag: false,
      showreview: false,
      mask: '',
      storeList: [],
      showdetail: false,
      detailjson: {}
    };
  },
  mounted: function mounted() {
    console.log(this.$route.query.id);
    if (this.$route.query.id) {
      this.getdetail(this.$route.query.id);
    }
  },
  methods: {
    close: function close() {
      this.$router.back();
    },
    getdetail: function getdetail(id) {
      var _this = this;
      (0, _warehouse.getwarehouseDetail)(id).then(function (res) {
        if (res.status == 200) {
          _this.detailjson = res.data;
          // this.showdetail = true;
        }
      });
    },
    getStoreList: function getStoreList(num) {
      var _this2 = this;
      (0, _product.getStoreList)({
        page: 1
      }).then(function (res) {
        _this2.storeList = res.data.list;
      }).catch(function (res) {
        // this.$message.error(res.message)
      });
    },
    revieworder: function revieworder(row) {
      this.handlerow = row;
      this.showreview = true;
      this.mask = '';
    },
    handlereview: function handlereview(state) {
      var _this3 = this;
      if (state == -1 && !this.mask) {
        this.$message.error('请填写拒绝备注');
        return;
      }
      (0, _review.review)(this.handlerow.id, {
        status: state,
        remark: this.mask
      }).then(function (res) {
        _this3.$message.success('审核成功');
      });
    },
    // 订单详情
    onOrderDetail: function onOrderDetail(order_id) {
      this.$router.push({
        name: "OrderList",
        query: {
          order_id: order_id
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList("");
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList("");
    },
    // 编辑
    onOrderMark: function onOrderMark(id) {
      // this.$modalForm(invoiceorderMarkApi(id)).then(() => this.getList(''))
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _warehouse.warehouselist)(this.tableFrom).then(function (res) {
        _this4.orderChartType = res.data.stat;
        _this4.tableData.data = res.data.list;
        _this4.tableData.total = res.data.count;
        _this4.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    }
  }
};