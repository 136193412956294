var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showData && _vm.showData.length > 0
    ? _c(
        "el-popover",
        { attrs: { placement: "left", width: "400", trigger: "hover" } },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", color: "#000" },
              attrs: {
                data: _vm.showData,
                height: "400",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "图片", width: "60" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (params) {
                        return [
                          _c("img", {
                            staticStyle: { width: "40px", height: "40px" },
                            attrs: { src: params.row.image, alt: "" },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1429613322
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "名称" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (params) {
                        return [
                          _c("div", { staticClass: "num" }, [
                            _vm._v(_vm._s(params.row.name)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3582178502
                ),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { slot: "reference", type: "text" },
              slot: "reference",
            },
            [_vm._v("已选择" + _vm._s(_vm.showData.length) + "件")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }