var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "playimage"
        ? [
            _c(
              "div",
              {
                staticClass: "top-img",
                style: "padding-top: " + _vm.facade.marginTop / 2 + "px;",
              },
              [_vm._m(0)]
            ),
          ]
        : _vm.content.type === "bindPrice"
        ? [
            _c("div", { staticClass: "blind-price-box" }, [
              _c(
                "div",
                {
                  staticClass: "price-box-content",
                  style:
                    "margin-top: " +
                    _vm.facade.marginTop / 2 +
                    "px;border-color:" +
                    _vm.facade.borderColor +
                    ";box-shadow: 0 0 10px " +
                    _vm.facade.borderColor +
                    ";color:" +
                    _vm.facade.color,
                },
                [
                  _vm._v("200 "),
                  _c(
                    "span",
                    {
                      staticClass: "price-box-content-sign",
                      style: "color:" + _vm.facade.color,
                    },
                    [_vm._v("元 / 个")]
                  ),
                ]
              ),
            ]),
          ]
        : _vm.content.type === "reducePrice"
        ? [
            _c(
              "div",
              {
                staticClass: "blind-price",
                style:
                  "margin-top:" +
                  _vm.facade.marginTop / 2 +
                  "px;color:" +
                  _vm.facade.color +
                  ";background-image:url(" +
                  _vm.facade.bgimage +
                  ")",
              },
              [_vm._v("最高可省10元")]
            ),
          ]
        : _vm.content.type === "consignee"
        ? [
            _c(
              "div",
              {
                staticClass: "blind-chance-box",
                style: "margin-top: " + _vm.facade.marginTop / 2 + "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "blind-chance-title",
                    style: "color:" + _vm.facade.topTitleColor,
                  },
                  [_vm._v("产出概率")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "blind-chance-list" }, [
                  _c("div", { staticClass: "blind-chance-item" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "blind-chance-name",
                        style: "color:" + _vm.facade.nameActiveColor,
                      },
                      [_vm._v("全部")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "blind-chance-num",
                        style: "color:" + _vm.facade.nameActiveColor,
                      },
                      [_vm._v("100%")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "blind-chance-item" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "blind-chance-name",
                        style: "color:" + _vm.facade.nameColor,
                      },
                      [_vm._v("全部")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "blind-chance-num",
                        style: "color:" + _vm.facade.nameColor,
                      },
                      [_vm._v("100%")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "blind-chance-item" }, [
                    _vm._m(3),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "blind-chance-name",
                        style: "color:" + _vm.facade.nameColor,
                      },
                      [_vm._v("全部")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "blind-chance-num",
                        style: "color:" + _vm.facade.nameColor,
                      },
                      [_vm._v("100%")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "blind-chance-item" }, [
                    _vm._m(4),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "blind-chance-name",
                        style: "color:" + _vm.facade.nameColor,
                      },
                      [_vm._v("全部")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "blind-chance-num",
                        style: "color:" + _vm.facade.nameColor,
                      },
                      [_vm._v("100%")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "blind-chance-item" }, [
                    _vm._m(5),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "blind-chance-name",
                        style: "color:" + _vm.facade.nameColor,
                      },
                      [_vm._v("全部")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "blind-chance-num",
                        style: "color:" + _vm.facade.nameColor,
                      },
                      [_vm._v("100%")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "blind-chance-sign",
                    style: "color:" + _vm.facade.signColor,
                  },
                  [
                    _vm._v(
                      "\n          小数点后三位四舍五入，故存在总值不为100%的可能\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "listTitle"
        ? [
            _c(
              "div",
              {
                staticClass: "list-title",
                style:
                  "margin-top: " +
                  _vm.facade.marginTop / 2 +
                  "px;color:" +
                  _vm.facade.color,
              },
              [_vm._v("开箱必出如下宝贝之一")]
            ),
          ]
        : _vm.content.type == "productmeta"
        ? [
            _c(
              "div",
              {
                staticClass: "compose-box",
                style: "margin-top:" + _vm.facade.marginTop / 2 + "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "compose-list",
                    style:
                      "background-color:" +
                      _vm.facade.listBgcolor +
                      ";background-image:url(" +
                      _vm.facade.listBgimage +
                      ");margin-left:" +
                      _vm.facade.marginLeft / 2 +
                      "px; margin-right:" +
                      _vm.facade.marginLeft / 2 +
                      "px;border-radius: " +
                      _vm.facade.borderRadius / 2 +
                      "px;",
                  },
                  [
                    _vm._m(6),
                    _vm._v(" "),
                    _c("div", { staticClass: "compose-right" }, [
                      _c(
                        "div",
                        {
                          staticClass: "compose-title",
                          style: "color:" + _vm.facade.metaColor,
                        },
                        [_vm._v("商品标题")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "price",
                          style: "color:" + _vm.facade.priceColor,
                        },
                        [_vm._v("￥"), _c("span", [_vm._v("19")])]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "compose-list",
                    style:
                      "background-color:" +
                      _vm.facade.listBgcolor +
                      ";background-image:url(" +
                      _vm.facade.listBgimage +
                      ");margin-left:" +
                      _vm.facade.marginLeft / 2 +
                      "px; margin-right:" +
                      _vm.facade.marginLeft / 2 +
                      "px;border-radius: " +
                      _vm.facade.borderRadius / 2 +
                      "px;",
                  },
                  [
                    _vm._m(7),
                    _vm._v(" "),
                    _c("div", { staticClass: "compose-right" }, [
                      _c(
                        "div",
                        {
                          staticClass: "compose-title",
                          style: "color:" + _vm.facade.metaColor,
                        },
                        [_vm._v("商品标题")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "price",
                          style: "color:" + _vm.facade.priceColor,
                        },
                        [_vm._v("￥"), _c("span", [_vm._v("19")])]
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type == "pricepop"
        ? [
            _c(
              "div",
              {
                staticClass: "price-box",
                style:
                  "background-color:" +
                  _vm.facade.bgColor +
                  ";border-color:" +
                  _vm.facade.popborderColor,
              },
              [
                _c("img", {
                  staticClass: "price-box-bg-img",
                  attrs: { src: _vm.facade.bgimage, alt: "" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "price-list" }, [
                  _c(
                    "div",
                    {
                      staticClass: "price-min-button orange",
                      style:
                        "border-color:" +
                        _vm.facade.borderColor +
                        ";background-image:url(" +
                        _vm.facade.oneBgimage +
                        ");background-color:" +
                        _vm.facade.oneBgcolor +
                        ";border-radius: " +
                        _vm.facade.borderRadius / 2 +
                        "px;",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "price-name",
                          style:
                            "font-size:" +
                            _vm.facade.nameSize / 2 +
                            "px;color:" +
                            _vm.facade.nameColor +
                            ";",
                        },
                        [_vm._v("价格名称")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "price-num",
                          style:
                            "font-size:" +
                            _vm.facade.priceSize / 2 +
                            "px;color:" +
                            _vm.facade.priceColor +
                            ";",
                        },
                        [_vm._v("1元")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "price-min-button red",
                      style:
                        "border-color:" +
                        _vm.facade.borderColor +
                        ";background-image:url(" +
                        _vm.facade.twoBgimage +
                        ");background-color:" +
                        _vm.facade.twoBgcolor +
                        ";border-radius: " +
                        _vm.facade.borderRadius / 2 +
                        "px;",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "price-name",
                          style:
                            "font-size:" +
                            _vm.facade.nameSize / 2 +
                            "px;color:" +
                            _vm.facade.nameColor +
                            ";",
                        },
                        [_vm._v("价格名称")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "price-num",
                          style:
                            "font-size:" +
                            _vm.facade.priceSize / 2 +
                            "px;color:" +
                            _vm.facade.priceColor +
                            ";",
                        },
                        [_vm._v("1元")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "price-prop-sign",
                          style:
                            "background-image:url(" +
                            _vm.facade.cutbgImage +
                            ");color:" +
                            _vm.facade.cutColor,
                        },
                        [_vm._v("\n            立省1元\n          ")]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "price-list" }, [
                  _c(
                    "div",
                    {
                      staticClass: "price-min-button zhise",
                      style:
                        "border-color:" +
                        _vm.facade.borderColor +
                        ";background-image:url(" +
                        _vm.facade.threeBgimage +
                        ");background-color:" +
                        _vm.facade.threeBgcolor +
                        ";border-radius: " +
                        _vm.facade.borderRadius / 2 +
                        "px;",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "price-name",
                          style:
                            "font-size:" +
                            _vm.facade.nameSize / 2 +
                            "px;color:" +
                            _vm.facade.nameColor +
                            ";",
                        },
                        [_vm._v("价格名称")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "price-num",
                          style:
                            "font-size:" +
                            _vm.facade.priceSize / 2 +
                            "px;color:" +
                            _vm.facade.priceColor +
                            ";",
                        },
                        [_vm._v("1元")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "price-prop-sign",
                          style:
                            "background-image:url(" +
                            _vm.facade.cutbgImage +
                            ");color:" +
                            _vm.facade.cutColor,
                        },
                        [_vm._v("\n            立省1元\n          ")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "price-min-button blue",
                      style:
                        "border-color:" +
                        _vm.facade.borderColor +
                        ";background-image:url(" +
                        _vm.facade.fourBgimage +
                        ");background-color:" +
                        _vm.facade.fourBgcolor +
                        ";border-radius: " +
                        _vm.facade.borderRadius / 2 +
                        "px;",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "price-name",
                          style:
                            "font-size:" +
                            _vm.facade.nameSize / 2 +
                            "px;color:" +
                            _vm.facade.nameColor +
                            ";",
                        },
                        [_vm._v("价格名称")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "price-num",
                          style:
                            "font-size:" +
                            _vm.facade.priceSize / 2 +
                            "px;color:" +
                            _vm.facade.priceColor +
                            ";",
                        },
                        [_vm._v("1元")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "price-prop-sign",
                          style:
                            "background-image:url(" +
                            _vm.facade.cutbgImage +
                            ");color:" +
                            _vm.facade.cutColor,
                        },
                        [_vm._v("\n            立省1元\n          ")]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        : _vm.content.type == "posterbg"
        ? [
            _c("img", {
              staticStyle: { width: "300px" },
              attrs: { src: _vm.facade.bgimage, alt: "" },
            }),
          ]
        : _vm.content.type == "bubblepop"
        ? [
            _c(
              "div",
              {
                staticClass: "bunddle-pop",
                style:
                  "width:" +
                  _vm.facade.popwidth / 2 +
                  "px;height:" +
                  _vm.facade.popwidth / 2 +
                  "px;background-color:" +
                  _vm.facade.bgcolor +
                  ";background-image:url(" +
                  _vm.facade.bgimage +
                  ")",
              },
              [
                _c("img", {
                  style:
                    "width:" +
                    _vm.facade.imgwidth / 2 +
                    "px;height:" +
                    _vm.facade.imgwidth / 2 +
                    "px;",
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
                    alt: "",
                  },
                }),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "item-flex-center",
        staticStyle: {
          width: "150px",
          height: "150px",
          "margin-left": "112.5px",
        },
      },
      [
        _c("img", {
          staticStyle: { width: "100%", height: "100%" },
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
            alt: "",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "blind-chance-image" }, [
      _c("img", {
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "blind-chance-image" }, [
      _c("img", {
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "blind-chance-image" }, [
      _c("img", {
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "blind-chance-image" }, [
      _c("img", {
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "blind-chance-image" }, [
      _c("img", {
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "compose-left" }, [
      _c("img", {
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "compose-left" }, [
      _c("img", {
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }