var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "set-content", staticStyle: { height: "100%" } },
    [
      _c(
        "el-scrollbar",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "padding-bottom": "120px" },
              attrs: {
                "label-width": "100px",
                size: "small",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.background,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "background", $$v)
                      },
                      expression: "facade.background",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "标题颜色" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.facade.title_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "title_color", $$v)
                        },
                        expression: "facade.title_color",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "black" } }, [
                        _vm._v("黑色"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "white" } }, [
                        _vm._v("白色"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "副标题颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.sub_title_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "sub_title_color", $$v)
                      },
                      expression: "facade.sub_title_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "radius", $$v)
                      },
                      expression: "facade.radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "fontW500 ft14",
                  staticStyle: { "margin-bottom": "20px" },
                },
                [_vm._v("优惠券面值区域")]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "渐变色开始" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FF6C6C" },
                    model: {
                      value: _vm.facade.gradient_start,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "gradient_start", $$v)
                      },
                      expression: "facade.gradient_start",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "渐变色结束" } },
                [
                  _c("colour", {
                    attrs: { colour: "#E33838" },
                    model: {
                      value: _vm.facade.gradient_end,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "gradient_end", $$v)
                      },
                      expression: "facade.gradient_end",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "价格字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.price_font_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "price_font_size", $$v)
                      },
                      expression: "facade.price_font_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "价格风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.price_font_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "price_font_weight", $$v)
                        },
                        expression: "facade.price_font_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "价格颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.proce_font_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "proce_font_color", $$v)
                      },
                      expression: "facade.proce_font_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "价格符号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.symbol_font_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "symbol_font_size", $$v)
                      },
                      expression: "facade.symbol_font_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "fontW500 ft14",
                  staticStyle: { "margin-bottom": "20px" },
                },
                [_vm._v("优惠券内容区域")]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.content_background,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "content_background", $$v)
                      },
                      expression: "facade.content_background",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "边框颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.content_border_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "content_border_color", $$v)
                      },
                      expression: "facade.content_border_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "内容距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.content_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "content_margin", $$v)
                      },
                      expression: "facade.content_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "名称字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.name_font_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "name_font_size", $$v)
                      },
                      expression: "facade.name_font_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "名称风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.name_font_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "name_font_weight", $$v)
                        },
                        expression: "facade.name_font_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "名称颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#666666" },
                    model: {
                      value: _vm.facade.name_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "name_color", $$v)
                      },
                      expression: "facade.name_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "未领取颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#999999" },
                    model: {
                      value: _vm.facade.status1_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "status1_color", $$v)
                      },
                      expression: "facade.status1_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "已领取颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#F76262" },
                    model: {
                      value: _vm.facade.status2_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "status2_color", $$v)
                      },
                      expression: "facade.status2_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠详情字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.desc_font_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "desc_font_size", $$v)
                      },
                      expression: "facade.desc_font_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠详情风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.desc_font_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "desc_font_weight", $$v)
                        },
                        expression: "facade.desc_font_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠详情颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FB7575" },
                    model: {
                      value: _vm.facade.desc_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "desc_color", $$v)
                      },
                      expression: "facade.desc_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠范围字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.message_font_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "message_font_size", $$v)
                      },
                      expression: "facade.message_font_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠范围风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.message_font_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "message_font_weight", $$v)
                        },
                        expression: "facade.message_font_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠范围颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#999999" },
                    model: {
                      value: _vm.facade.message_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "message_color", $$v)
                      },
                      expression: "facade.message_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "详细颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#0088FF" },
                    model: {
                      value: _vm.facade.more_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "more_color", $$v)
                      },
                      expression: "facade.more_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠时间字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.time_font_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "time_font_size", $$v)
                      },
                      expression: "facade.time_font_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠时间风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.time_font_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "time_font_weight", $$v)
                        },
                        expression: "facade.time_font_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠时间颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#999999" },
                    model: {
                      value: _vm.facade.time_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "time_color", $$v)
                      },
                      expression: "facade.time_color",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }