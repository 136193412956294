var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "digital-collection mt20" },
    [
      _c("div", { staticClass: "tab flex align-items-c" }, [
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c(
              "el-radio-group",
              {
                staticClass: "mr20",
                staticStyle: { display: "block" },
                attrs: { type: "button", size: "small" },
                on: {
                  change: function ($event) {
                    return _vm.selectChange(_vm.date)
                  },
                },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              },
              _vm._l(_vm.fromList.fromTxt, function (item, i) {
                return _c(
                  "el-radio-button",
                  { key: i, attrs: { label: item.val } },
                  [_vm._v(_vm._s(item.text))]
                )
              }),
              1
            ),
            _vm._v(" "),
            _c("el-date-picker", {
              staticStyle: { width: "250px" },
              attrs: {
                "value-format": "yyyy/MM/dd",
                format: "yyyy/MM/dd",
                size: "small",
                type: "daterange",
                placement: "bottom-end",
                placeholder: "自定义时间",
              },
              on: { change: _vm.onchangeTime },
              model: {
                value: _vm.timeVal,
                callback: function ($$v) {
                  _vm.timeVal = $$v
                },
                expression: "timeVal",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab flex align-items-c mt10" },
        [
          _c(
            "el-input",
            {
              staticClass: "w200",
              attrs: { placeholder: "任务名称" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.keyword,
                callback: function ($$v) {
                  _vm.keyword = $$v
                },
                expression: "keyword",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "w200 ml10",
              attrs: { placeholder: "任务状态", clearable: "" },
              model: {
                value: _vm.status,
                callback: function ($$v) {
                  _vm.status = $$v
                },
                expression: "status",
              },
            },
            [_c("el-option", { attrs: { value: 1, label: "任务状态1" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.addAction },
            },
            [_vm._v("新增任务")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.exportOrder },
            },
            [_vm._v("导出")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "text" }, on: { click: _vm.getExportFileList } },
            [_vm._v("导出已生成列表")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "order-table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                key: "1",
                attrs: { label: "任务名称", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v("空投任务名称")])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "2",
                attrs: { label: "空投作品", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "flex align-items-c" }, [
                          _c("div", { staticClass: "work-img" }, [
                            _c("img", {
                              attrs: { src: row.img + "!120a", alt: "" },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "user-info flex-one ml10",
                              staticStyle: { overflow: "hidden" },
                            },
                            [_c("div", [_vm._v(_vm._s(row.name))])]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "3",
                attrs: { label: "预计空投", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v("100")])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "4",
                attrs: { label: "空投成功", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v("200")])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "5",
                attrs: { label: "空投失败", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v("30")])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "6",
                attrs: { label: "任务状态", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "color-lv" }, [
                          _vm._v("已执行"),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "20",
                attrs: { label: "创建成员", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.admin))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "21",
                attrs: { label: "创建时间", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v("2021-04-13 12:56:00")])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c(
                "el-table-column",
                { key: "22", attrs: { label: "操作", width: "100" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "mini" },
                      on: { click: _vm.showDetailAction },
                    },
                    [_vm._v("查看详情")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex justify-e" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-size": _vm.queryParams.limit,
                  "current-page": _vm.queryParams.page,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: { "current-change": _vm.getList },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "空投详情", visible: _vm.deliverDetail.show },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.deliverDetail, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-input",
                {
                  staticClass: "input-with-select w200",
                  attrs: { placeholder: "买家昵称/姓名/手机号" },
                  on: { change: _vm.goSearchUserAction },
                  model: {
                    value: _vm.deliverDetail.keyword,
                    callback: function ($$v) {
                      _vm.$set(_vm.deliverDetail, "keyword", $$v)
                    },
                    expression: "deliverDetail.keyword",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: { click: _vm.goSearchUserAction },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "w200 ml10",
                  attrs: { placeholder: "空投状态", clearable: "" },
                  on: { change: _vm.goSearchUserAction },
                  model: {
                    value: _vm.deliverDetail.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.deliverDetail, "status", $$v)
                    },
                    expression: "deliverDetail.status",
                  },
                },
                [_c("el-option", { attrs: { value: 1, label: "空投状态1" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("导出已生成列表")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.deliverDetail.listLoading,
                      expression: "deliverDetail.listLoading",
                    },
                  ],
                  staticClass: "order-table",
                  staticStyle: { width: "100%", height: "400px" },
                  attrs: {
                    data: _vm.deliverDetail.tableData.data,
                    size: "mini",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "空投用户" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v("空投用户")])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "通证标识" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v("通证标识")])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "空投状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v("空投状态")])]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20 flex justify-e" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-size": _vm.deliverDetail.queryParams.limit,
                      "current-page": _vm.deliverDetail.queryParams.page,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.deliverDetail.tableData.total,
                    },
                    on: { "current-change": _vm.loadUserAction },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新建空投任务",
            visible: _vm.newDeliverDetail.show,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.newDeliverDetail, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: {
                model: _vm.newDeliverDetail,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "任务名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入任务名称" },
                    model: {
                      value: _vm.newDeliverDetail.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.newDeliverDetail, "name", $$v)
                      },
                      expression: "newDeliverDetail.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "空投作品", prop: "work" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择空投作品" },
                      model: {
                        value: _vm.newDeliverDetail.work,
                        callback: function ($$v) {
                          _vm.$set(_vm.newDeliverDetail, "work", $$v)
                        },
                        expression: "newDeliverDetail.work",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "作品1", value: "zuop1" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "作品2", value: "zuop2" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  style:
                    _vm.newDeliverDetail.obj != 1 ? "margin-bottom: 0" : "",
                  attrs: { label: "空投对象" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.newDeliverDetail.obj,
                        callback: function ($$v) {
                          _vm.$set(_vm.newDeliverDetail, "obj", $$v)
                        },
                        expression: "newDeliverDetail.obj",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("全部用户"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("精准投放"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "3" } }, [
                        _vm._v("条件投放"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.newDeliverDetail.obj != 1
                ? _c("el-form-item", { attrs: { label: " " } }, [
                    _c(
                      "div",
                      { staticClass: "obj-view" },
                      [
                        _vm.newDeliverDetail.obj == 2
                          ? _c("div", { staticClass: "dl flex" }, [
                              _c("div", { staticClass: "l" }, [
                                _vm._v("投放用户"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "r" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      resize: "none",
                                      placeholder:
                                        "请输入用户UID，多个用户用逗号隔开，单次建议1000以内",
                                    },
                                    model: {
                                      value: _vm.newDeliverDetail.user,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newDeliverDetail,
                                          "user",
                                          $$v
                                        )
                                      },
                                      expression: "newDeliverDetail.user",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm.newDeliverDetail.obj == 3
                          ? [
                              _c(
                                "div",
                                { staticClass: "dl flex align-items-c" },
                                [
                                  _c("div", { staticClass: "l" }, [
                                    _vm._v("注册时间"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "r" },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "daterange",
                                          "range-separator": "至",
                                          "value-format": "timestamp",
                                          "start-placeholder": "注册开始日期",
                                          "end-placeholder": "注册结束日期",
                                        },
                                        model: {
                                          value:
                                            _vm.newDeliverDetail.condition
                                              .registerTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newDeliverDetail.condition,
                                              "registerTime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "newDeliverDetail.condition.registerTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "dl flex align-items-c" },
                                [
                                  _c("div", { staticClass: "l" }, [
                                    _vm._v("最低支付金额"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "r" },
                                    [
                                      _c("el-input", {
                                        staticStyle: { "text-align": "center" },
                                        attrs: {
                                          placeholder: "请输入用户累计订单金额",
                                        },
                                        model: {
                                          value:
                                            _vm.newDeliverDetail.condition
                                              .minPayPrice,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newDeliverDetail.condition,
                                              "minPayPrice",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "newDeliverDetail.condition.minPayPrice",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "dl flex align-items-c" },
                                [
                                  _c("div", { staticClass: "l" }, [
                                    _vm._v("最低支付次数"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "r" },
                                    [
                                      _c("el-input", {
                                        staticStyle: { "text-align": "center" },
                                        attrs: {
                                          placeholder: "请输入用户累计订单次数",
                                        },
                                        model: {
                                          value:
                                            _vm.newDeliverDetail.condition
                                              .minPayTimes,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newDeliverDetail.condition,
                                              "minPayTimes",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "newDeliverDetail.condition.minPayTimes",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "dl flex align-items-c" },
                                [
                                  _c("div", { staticClass: "l" }, [
                                    _vm._v("最近支付时间"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "r" },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "daterange",
                                          "value-format": "timestamp",
                                          "range-separator": "至",
                                          "start-placeholder": "支付开始日期",
                                          "end-placeholder": "支付结束日期",
                                        },
                                        model: {
                                          value:
                                            _vm.newDeliverDetail.condition
                                              .latelyPayTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.newDeliverDetail.condition,
                                              "latelyPayTime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "newDeliverDetail.condition.latelyPayTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "空投时间" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.newDeliverDetail.timeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.newDeliverDetail, "timeType", $$v)
                        },
                        expression: "newDeliverDetail.timeType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("立即空投"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("定时空投"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.newDeliverDetail.timeType == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "设置时间", prop: "time" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "value-format": "timestamp",
                          placeholder: "选择日期时间",
                          align: "right",
                          "picker-options": _vm.pickerOptions,
                        },
                        model: {
                          value: _vm.newDeliverDetail.time,
                          callback: function ($$v) {
                            _vm.$set(_vm.newDeliverDetail, "time", $$v)
                          },
                          expression: "newDeliverDetail.time",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _vm._v(" "),
                  _c("el-button", [_vm._v("取消")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }