var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm ",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "110px",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "视频封面" } }, [
                _c(
                  "div",
                  {
                    staticClass: "upLoadPicBox",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap("uploadImage")
                      },
                    },
                  },
                  [
                    _vm.ruleForm.uploadImage
                      ? _c("div", { staticClass: "pictrue" }, [
                          _c("img", {
                            attrs: { src: _vm.ruleForm.uploadImage + "!120a" },
                          }),
                        ])
                      : _c("div", { staticClass: "upLoad" }, [
                          _c("i", {
                            staticClass: "el-icon-camera cameraIconfont",
                          }),
                        ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "模板类型：", prop: "type" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w300",
                              model: {
                                value: _vm.ruleForm.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "type", $$v)
                                },
                                expression: "ruleForm.type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: 1, label: "云总部模版" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 2, label: "IP星球模版" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "模版名称：", prop: "businessScenario" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c("el-input", {
                        staticClass: "w300",
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "业务场景：", prop: "businessScenario" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w300",
                          model: {
                            value: _vm.ruleForm.businessScenario,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "businessScenario", $$v)
                            },
                            expression: "ruleForm.businessScenario",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: 1, label: "拍卖" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 2, label: "商品" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 3, label: "作品" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择分类：", prop: "firstCategoryId" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w300",
                            on: { change: _vm.changeTwoCategory },
                            model: {
                              value: _vm.ruleForm.firstCategoryId,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "firstCategoryId", $$v)
                              },
                              expression: "ruleForm.firstCategoryId",
                            },
                          },
                          _vm._l(_vm.categoryList, function (m, i) {
                            return _c("el-option", {
                              key: i,
                              attrs: { value: m.id, label: m.name },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex align-items-c ml10" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w300",
                            model: {
                              value: _vm.ruleForm.secondaryCategoryId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "secondaryCategoryId",
                                  $$v
                                )
                              },
                              expression: "ruleForm.secondaryCategoryId",
                            },
                          },
                          _vm._l(_vm.categoryTwoList, function (m, i) {
                            return _c("el-option", {
                              key: i,
                              attrs: { value: m.id, label: m.name },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.attrList, function (m, i) {
                return _c(
                  "div",
                  { key: i },
                  [
                    m.attrName == "内容类型"
                      ? [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "内容类型：",
                                prop: "contentLatitudeId",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex align-items-c" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w300",
                                      model: {
                                        value: _vm.ruleForm.contentLatitudeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "contentLatitudeId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.contentLatitudeId",
                                      },
                                    },
                                    _vm._l(
                                      m.sysAttrValueList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: "" + index + i,
                                          attrs: {
                                            value: item.id,
                                            label: item.attrValueName,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      : m.attrName == "内容定义"
                      ? [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "内容定义：",
                                prop: "contentLocalizationId",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex align-items-c" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w300",
                                      model: {
                                        value:
                                          _vm.ruleForm.contentLocalizationId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "contentLocalizationId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.contentLocalizationId",
                                      },
                                    },
                                    _vm._l(
                                      m.sysAttrValueList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: "" + index + i,
                                          attrs: {
                                            value: item.id,
                                            label: item.attrValueName,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      : m.attrName == "作品形状"
                      ? [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "作品形状：",
                                prop: "workShapeId",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex align-items-c" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w300",
                                      model: {
                                        value: _vm.ruleForm.workShapeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "workShapeId",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.workShapeId",
                                      },
                                    },
                                    _vm._l(
                                      m.sysAttrValueList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: "" + index + i,
                                          attrs: {
                                            value: item.id,
                                            label: item.attrValueName,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "标签：", prop: "workShapeId" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.ruleForm.tagIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "tagIds", $$v)
                            },
                            expression: "ruleForm.tagIds",
                          },
                        },
                        _vm._l(_vm.tagList, function (m, i) {
                          return _c(
                            "el-checkbox",
                            { key: i, attrs: { label: m.id } },
                            [_vm._v(_vm._s(m.name))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.saveAction("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确认提交")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }