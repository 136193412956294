"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'OrderDetail',
  props: {
    orderDatalist: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      // orderDatalist: null,
      loading: false
    };
  },
  mounted: function mounted() {},
  methods: {}
};