var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.content.style == 1
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style:
              "padding: 0 " +
              _vm.facade.page_padding / 2 +
              "px;background: " +
              _vm.facade.background_color +
              ";",
          },
          [
            _c(
              "div",
              {
                staticClass: "one-list mt0",
                style:
                  "margin-top:" +
                  _vm.facade.item_bottom_padding / 2 +
                  "px;border-top-right-radius: " +
                  _vm.facade.img_radius / 2 +
                  "px;border-top-left-radius: " +
                  _vm.facade.img_radius / 2 +
                  "px;border-bottom-left-radius: " +
                  _vm.facade.status_radius / 2 +
                  "px;border-bottom-right-radius: " +
                  _vm.facade.status_radius / 2 +
                  "px;background: " +
                  _vm.facade.status_background +
                  " url(" +
                  _vm.facade.bg_image +
                  ") repeat left center;",
                on: {
                  click: function ($event) {
                    return _vm.goDetailAction(_vm.item)
                  },
                },
              },
              [
                _c("div", { staticClass: "time" }, [
                  _vm._v("即将开售 09:04:12"),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "text color-black" }, [
                  _vm._v(
                    "刺杀罪恶之文化侵蚀刺杀罪恶之文化侵蚀刺杀罪恶之文化侵蚀刺杀罪恶之文化侵蚀"
                  ),
                ]),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _vm._m(2),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "one-list",
                style:
                  "margin-top:" +
                  _vm.facade.item_bottom_padding / 2 +
                  "px;border-top-right-radius: " +
                  _vm.facade.img_radius / 2 +
                  "px;border-top-left-radius: " +
                  _vm.facade.img_radius / 2 +
                  "px;border-bottom-left-radius: " +
                  _vm.facade.status_radius / 2 +
                  "px;border-bottom-right-radius: " +
                  _vm.facade.status_radius / 2 +
                  "px;background: " +
                  _vm.facade.status_background +
                  " url(" +
                  _vm.facade.bg_image +
                  ") repeat left center;",
                on: {
                  click: function ($event) {
                    return _vm.goDetailAction(_vm.item)
                  },
                },
              },
              [
                _c("div", { staticClass: "time" }, [
                  _vm._v("敬请期待 4.12 09:00 开售"),
                ]),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _c("div", { staticClass: "text color-black" }, [
                  _vm._v(
                    "刺杀罪恶之文化侵蚀刺杀罪恶之文化侵蚀刺杀罪恶之文化侵蚀刺杀罪恶之文化侵蚀"
                  ),
                ]),
                _vm._v(" "),
                _vm._m(4),
                _vm._v(" "),
                _vm._m(5),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.content.style == 2
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style:
              "padding: 0 " +
              _vm.facade.page_padding / 2 +
              "px;background: " +
              _vm.facade.background_color +
              ";",
          },
          [
            _c("div", { staticClass: "list flex" }, [
              _c(
                "div",
                {
                  staticClass: "r-view flex mt0",
                  style:
                    "margin-top:" +
                    _vm.facade.item_bottom_padding / 2 +
                    "px;border-top-right-radius: " +
                    _vm.facade.img_radius / 2 +
                    "px;border-top-left-radius: " +
                    _vm.facade.img_radius / 2 +
                    "px;border-bottom-left-radius: " +
                    _vm.facade.status_radius / 2 +
                    "px;border-bottom-right-radius: " +
                    _vm.facade.status_radius / 2 +
                    "px;background: " +
                    _vm.facade.status_background +
                    " url(" +
                    _vm.facade.bg_image +
                    ") repeat left center;",
                },
                [
                  _c(
                    "div",
                    { staticClass: "img" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2022424/image/1650788643475513463.jpg!m640",
                          fit:
                            _vm.content.img_style === 2 ? "contain" : "cover",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._m(6),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "list flex" }, [
              _c(
                "div",
                {
                  staticClass: "r-view flex",
                  style:
                    "margin-top:" +
                    _vm.facade.item_bottom_padding / 2 +
                    "px;border-top-right-radius: " +
                    _vm.facade.img_radius / 2 +
                    "px;border-top-left-radius: " +
                    _vm.facade.img_radius / 2 +
                    "px;border-bottom-left-radius: " +
                    _vm.facade.status_radius / 2 +
                    "px;border-bottom-right-radius: " +
                    _vm.facade.status_radius / 2 +
                    "px;background: " +
                    _vm.facade.status_background +
                    " url(" +
                    _vm.facade.bg_image +
                    ") repeat left center;",
                },
                [
                  _c(
                    "div",
                    { staticClass: "img" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2022424/image/1650788643475513463.jpg!m640",
                          fit:
                            _vm.content.img_style === 2 ? "contain" : "cover",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._m(7),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "list flex" }, [
              _c(
                "div",
                {
                  staticClass: "r-view flex",
                  style:
                    "margin-top:" +
                    _vm.facade.item_bottom_padding / 2 +
                    "px;border-top-right-radius: " +
                    _vm.facade.img_radius / 2 +
                    "px;border-top-left-radius: " +
                    _vm.facade.img_radius / 2 +
                    "px;border-bottom-left-radius: " +
                    _vm.facade.status_radius / 2 +
                    "px;border-bottom-right-radius: " +
                    _vm.facade.status_radius / 2 +
                    "px;background: " +
                    _vm.facade.status_background +
                    " url(" +
                    _vm.facade.bg_image +
                    ") repeat left center;",
                },
                [
                  _c(
                    "div",
                    { staticClass: "img" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2022424/image/1650788643475513463.jpg!m640",
                          fit:
                            _vm.content.img_style === 2 ? "contain" : "cover",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._m(8),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "list flex" }, [
              _c(
                "div",
                {
                  staticClass: "r-view flex",
                  style:
                    "margin-top:" +
                    _vm.facade.item_bottom_padding / 2 +
                    "px;border-top-right-radius: " +
                    _vm.facade.img_radius / 2 +
                    "px;border-top-left-radius: " +
                    _vm.facade.img_radius / 2 +
                    "px;border-bottom-left-radius: " +
                    _vm.facade.status_radius / 2 +
                    "px;border-bottom-right-radius: " +
                    _vm.facade.status_radius / 2 +
                    "px;background: " +
                    _vm.facade.status_background +
                    " url(" +
                    _vm.facade.bg_image +
                    ") repeat left center;",
                },
                [
                  _c(
                    "div",
                    { staticClass: "img" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2022424/image/1650788643475513463.jpg!m640",
                          fit:
                            _vm.content.img_style === 2 ? "contain" : "cover",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._m(9),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "list flex" }, [
              _c(
                "div",
                {
                  staticClass: "r-view flex",
                  style:
                    "margin-top:" +
                    _vm.facade.item_bottom_padding / 2 +
                    "px;border-top-right-radius: " +
                    _vm.facade.img_radius / 2 +
                    "px;border-top-left-radius: " +
                    _vm.facade.img_radius / 2 +
                    "px;border-bottom-left-radius: " +
                    _vm.facade.status_radius / 2 +
                    "px;border-bottom-right-radius: " +
                    _vm.facade.status_radius / 2 +
                    "px;background: " +
                    _vm.facade.status_background +
                    " url(" +
                    _vm.facade.bg_image +
                    ") repeat left center;",
                },
                [
                  _c(
                    "div",
                    { staticClass: "img" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2022424/image/1650788643475513463.jpg!m640",
                          fit:
                            _vm.content.img_style === 2 ? "contain" : "cover",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._m(10),
                ]
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img" }, [
      _c("img", {
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2022424/image/1650788643475513463.jpg!m640",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tags flex align-items-c" }, [
      _c("div", { staticClass: "num" }, [_vm._v("限量500份")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bottom flex" }, [
      _c("div", { staticClass: "l-price" }, [
        _vm._v("￥"),
        _c("span", [_vm._v("39.00")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img" }, [
      _c("img", {
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2022424/image/1650788643475513463.jpg!m640",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tags flex align-items-c" }, [
      _c("div", { staticClass: "num" }, [_vm._v("限量500份")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bottom flex" }, [
      _c("div", { staticClass: "l-price" }, [
        _vm._v("￥"),
        _c("span", [_vm._v("39.00")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-r" }, [
      _c("div", { staticClass: "name line1" }, [
        _vm._v("刺杀罪恶之文化侵蚀刺杀罪恶之文化侵蚀"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count" }, [_vm._v("限量500份")]),
      _vm._v(" "),
      _c("div", { staticClass: "price" }, [
        _vm._v("￥"),
        _c("span", [_vm._v("1880.00")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-r" }, [
      _c("div", { staticClass: "name line1" }, [
        _vm._v("刺杀罪恶之文化侵蚀刺杀罪恶之文化侵蚀"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count" }, [_vm._v("限量500份")]),
      _vm._v(" "),
      _c("div", { staticClass: "price" }, [
        _vm._v("￥"),
        _c("span", [_vm._v("1880.00")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-r" }, [
      _c("div", { staticClass: "name line1" }, [
        _vm._v("刺杀罪恶之文化侵蚀刺杀罪恶之文化侵蚀"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count" }, [_vm._v("限量500份")]),
      _vm._v(" "),
      _c("div", { staticClass: "price" }, [
        _vm._v("￥"),
        _c("span", [_vm._v("1880.00")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-r" }, [
      _c("div", { staticClass: "name line1" }, [
        _vm._v("刺杀罪恶之文化侵蚀刺杀罪恶之文化侵蚀"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count" }, [_vm._v("限量500份")]),
      _vm._v(" "),
      _c("div", { staticClass: "price" }, [
        _vm._v("￥"),
        _c("span", [_vm._v("1880.00")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-r" }, [
      _c("div", { staticClass: "name line1" }, [
        _vm._v("刺杀罪恶之文化侵蚀刺杀罪恶之文化侵蚀"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count" }, [_vm._v("限量500份")]),
      _vm._v(" "),
      _c("div", { staticClass: "price" }, [
        _vm._v("￥"),
        _c("span", [_vm._v("1880.00")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }