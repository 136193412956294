var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "r flex align-items-c", on: { click: _vm.showSetAction } },
    [
      _c("div", [_vm._v("设置风格")]),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20220109/85ca05786bf8fcee7ece3f1d524c379b.png",
          alt: "",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }