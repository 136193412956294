"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var defaultObj = {
  image: '',
  slider_image: [],
  store_name: '',
  store_info: '',
  keyword: '',
  brand_id: '',
  // 品牌id
  cate_id: '',
  // 平台分类id
  mer_cate_id: [],
  // 商户分类id
  unit_name: '',
  sort: 0,
  is_show: 0,
  is_benefit: 0,
  is_new: 0,
  is_good: 0,
  temp_id: '',
  attrValue: [{
    image: '',
    price: null,
    cost: null,
    ot_price: null,
    stock: null,
    bar_code: '',
    weight: null,
    volume: null
  }],
  attr: [],
  selectRule: '',
  extension_type: 0,
  content: '',
  spec_type: 0
};
var objTitle = {
  price: {
    title: '售价'
  },
  cost: {
    title: '成本价'
  },
  ot_price: {
    title: '原价'
  },
  stock: {
    title: '库存'
  },
  bar_code: {
    title: '商品编号'
  },
  weight: {
    title: '重量（KG）'
  },
  volume: {
    title: '体积(m³)'
  }
};
var proOptions = [{
  name: '是否热卖',
  value: 'is_hot'
}, {
  name: '优品推荐',
  value: 'is_good'
}, {
  name: '促销单品',
  value: 'is_benefit'
}, {
  name: '是否精品',
  value: 'is_best'
}, {
  name: '是否新品',
  value: 'is_new'
}];
var _default2 = exports.default = {
  name: 'Info',
  props: {
    isShow: {
      type: Boolean,
      default: true
    },
    ids: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      rules: {
        status: [{
          required: true,
          message: '请选择审核状态',
          trigger: 'change'
        }],
        refusal: [{
          required: true,
          message: '请填写拒绝原因',
          trigger: 'blur'
        }]
      },
      proId: 0,
      ruleForm: {
        refusal: '',
        status: 1,
        id: ''
      },
      formThead: Object.assign({}, objTitle),
      manyTabDate: {},
      manyTabTit: {},
      loading: false,
      dialogVisible: false,
      activeNames: 'first',
      projectData: {},
      recommend: proOptions,
      checkboxGroup: [],
      OneattrValue: [Object.assign({}, defaultObj.attrValue[0])],
      // 单规格
      ManyAttrValue: [Object.assign({}, defaultObj.attrValue[0])] // 多规格
    };
  },
  computed: {
    attrValue: function attrValue() {
      var obj = Object.assign({}, defaultObj.attrValue[0]);
      delete obj.image;
      return obj;
    },
    oneFormBatch: function oneFormBatch() {
      var obj = [Object.assign({}, defaultObj.attrValue[0])];
      delete obj[0].bar_code;
      return obj;
    }
  },
  methods: {
    onSubmit: function onSubmit() {
      var _this = this;
      this.isShow ? this.ruleForm.id = this.proId : this.ruleForm.id = this.ids;
      (0, _product.productStatusApi)(this.ruleForm).then(function (res) {
        _this.$message.success(res.message);
        _this.dialogVisible = false;
        _this.activeNames = 'first';
        _this.$emit('subSuccess');
      }).catch(function (res) {
        _this.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.activeNames = 'first';
    },
    getInfo: function getInfo(id) {
      var _this2 = this;
      this.proId = id;
      this.loading = true;
      (0, _product.productDetailApi)(id).then(function (res) {
        _this2.projectData = res.data;
        if (_this2.projectData.spec_type === 0) {
          _this2.OneattrValue = res.data.attrValue;
        } else {
          _this2.ManyAttrValue = res.data.attrValue;
        }
        var tmp = {};
        var tmpTab = {};
        _this2.projectData.attr.forEach(function (o, i) {
          tmp['value' + i] = {
            title: o.value
          };
          tmpTab['value' + i] = '';
        });
        _this2.manyTabDate = tmpTab;
        _this2.manyTabTit = tmp;
        _this2.formThead = Object.assign({}, _this2.formThead, tmp);
        if (_this2.projectData.is_hot === 1) _this2.checkboxGroup.push('is_hot');
        if (_this2.projectData.is_good === 1) _this2.checkboxGroup.push('is_good');
        if (_this2.projectData.is_benefit === 1) _this2.checkboxGroup.push('is_benefit');
        if (_this2.projectData.is_best === 1) _this2.checkboxGroup.push('is_best');
        if (_this2.projectData.is_new === 1) _this2.checkboxGroup.push('is_new');
        _this2.loading = false;
      }).catch(function (res) {
        // this.$message.error(res.message)
        _this2.loading = false;
      });
    }
  }
};