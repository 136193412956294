var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rubik" }, [
    _vm.size < 7
      ? _c("div", { staticClass: "advertising" }, [
          _vm.size == 1
            ? _c(
                "div",
                {
                  staticClass: "advertising-item-1",
                  style: {
                    margin: _vm.imgLine1Margin,
                    width: _vm.imgLine1Width + "px",
                  },
                },
                [
                  _vm.adverArray[0].url
                    ? _c(
                        "el-image",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { src: _vm.adverArray[0].url, fit: "cover" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error",
                            },
                            [
                              _c("i", {
                                staticClass: "le-icon le-icon-morentupian",
                              }),
                            ]
                          ),
                        ]
                      )
                    : _c("el-image", {
                        style:
                          "width: " +
                          _vm.imgLine1Width +
                          "px;height: " +
                          _vm.imgLine1Width +
                          "px;",
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                          fit: "cover",
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.size == 2
            ? _c("div", { staticClass: "advertising-item-2" }, [
                _vm.adverArray[0]
                  ? _c(
                      "div",
                      { staticClass: "advertising-item-image" },
                      [
                        _vm.adverArray[0].url
                          ? _c(
                              "el-image",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.adverArray[0].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "le-icon le-icon-morentupian",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _c("el-image", {
                              staticStyle: { width: "375px", height: "375px" },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[1]
                  ? _c(
                      "div",
                      { staticClass: "advertising-item-image" },
                      [
                        _vm.adverArray[1].url
                          ? _c(
                              "el-image",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.adverArray[1].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "le-icon le-icon-morentupian",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _c("el-image", {
                              staticStyle: {
                                width: "375px",
                                height: "375px",
                                "border-left": "1px solid #fff",
                              },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.size == 3
            ? _c("div", { staticClass: "advertising-item-3" }, [
                _vm.adverArray[0]
                  ? _c(
                      "div",
                      { staticClass: "advertising-item-image" },
                      [
                        _vm.adverArray[0].url
                          ? _c(
                              "el-image",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.adverArray[0].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "le-icon le-icon-morentupian",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _c("el-image", {
                              staticStyle: { width: "250px", height: "250px" },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[1]
                  ? _c(
                      "div",
                      { staticClass: "advertising-item-image" },
                      [
                        _vm.adverArray[1].url
                          ? _c(
                              "el-image",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.adverArray[1].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "le-icon le-icon-morentupian",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _c("el-image", {
                              staticStyle: {
                                width: "250px",
                                height: "250px",
                                "border-left": "1px solid #fff",
                              },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[2]
                  ? _c(
                      "div",
                      { staticClass: "advertising-item-image" },
                      [
                        _vm.adverArray[2].url
                          ? _c(
                              "el-image",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.adverArray[2].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "le-icon le-icon-morentupian",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _c("el-image", {
                              staticStyle: {
                                width: "250px",
                                height: "250px",
                                "border-left": "1px solid #fff",
                              },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.size == 4
            ? _c("div", { staticClass: "advertising-item-4" }, [
                _vm.adverArray[0]
                  ? _c(
                      "div",
                      {
                        staticClass: "advertising-item-image",
                        style: {
                          width: "375px",
                          height: "375px",
                          top: 0,
                          left: 0,
                        },
                      },
                      [
                        _vm.adverArray[0].url
                          ? _c(
                              "el-image",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.adverArray[0].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "le-icon le-icon-morentupian",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _c("el-image", {
                              staticStyle: {
                                width: "100%",
                                height: "100%",
                                "border-right": "1px solid #fff",
                              },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[1]
                  ? _c(
                      "div",
                      {
                        staticClass: "advertising-item-image",
                        style: {
                          width: "375px",
                          height: "188px",
                          top: 0,
                          left: "375px",
                        },
                      },
                      [
                        _vm.adverArray[1].url
                          ? _c(
                              "el-image",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.adverArray[1].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "le-icon le-icon-morentupian",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _c("el-image", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[2]
                  ? _c(
                      "div",
                      {
                        staticClass: "advertising-item-image",
                        style: {
                          width: "375px",
                          height: "188px",
                          top: "188px",
                          left: "375px",
                        },
                      },
                      [
                        _vm.adverArray[2].url
                          ? _c(
                              "el-image",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.adverArray[2].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "le-icon le-icon-morentupian",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _c("el-image", {
                              staticStyle: {
                                width: "100%",
                                height: "100%",
                                "border-top": "1px solid #fff",
                              },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.size == 5
            ? _c("div", { staticClass: "advertising-item-5" }, [
                _vm.adverArray[0]
                  ? _c(
                      "div",
                      { staticClass: "advertising-item-image" },
                      [
                        _vm.adverArray[0].url
                          ? _c(
                              "el-image",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.adverArray[0].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "le-icon le-icon-morentupian",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _c("el-image", {
                              staticStyle: { width: "100%", height: "188px" },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[1]
                  ? _c(
                      "div",
                      { staticClass: "advertising-item-image" },
                      [
                        _vm.adverArray[1].url
                          ? _c(
                              "el-image",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.adverArray[1].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "le-icon le-icon-morentupian",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _c("el-image", {
                              staticStyle: {
                                width: "100%",
                                height: "188px",
                                "border-top": "1px solid #fff",
                                "border-left": "1px solid #fff",
                              },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[2]
                  ? _c(
                      "div",
                      { staticClass: "advertising-item-image" },
                      [
                        _vm.adverArray[2].url
                          ? _c(
                              "el-image",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.adverArray[2].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "le-icon le-icon-morentupian",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _c("el-image", {
                              staticStyle: { width: "100%", height: "188px" },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[3]
                  ? _c(
                      "div",
                      { staticClass: "advertising-item-image" },
                      [
                        _vm.adverArray[3].url
                          ? _c(
                              "el-image",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.adverArray[3].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "le-icon le-icon-morentupian",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _c("el-image", {
                              staticStyle: {
                                width: "100%",
                                height: "188px",
                                "border-top": "1px solid #fff",
                                "border-left": "1px solid #fff",
                              },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.size == 6
            ? _c("div", { staticClass: "advertising-item-4" }, [
                _vm.adverArray[0]
                  ? _c(
                      "div",
                      {
                        staticClass: "advertising-item-image",
                        style: {
                          width: "375px",
                          height: "188px",
                          top: 0,
                          left: 0,
                        },
                      },
                      [
                        _vm.adverArray[0].url
                          ? _c(
                              "el-image",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.adverArray[0].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "le-icon le-icon-morentupian",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _c("el-image", {
                              staticStyle: { width: "100%", height: "188px" },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[1]
                  ? _c(
                      "div",
                      {
                        staticClass: "advertising-item-image",
                        style: {
                          width: "375px",
                          height: "188px",
                          top: "188px",
                          left: 0,
                        },
                      },
                      [
                        _vm.adverArray[1].url
                          ? _c(
                              "el-image",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.adverArray[1].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "le-icon le-icon-morentupian",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _c("el-image", {
                              staticStyle: { width: "100%", height: "188px" },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[2]
                  ? _c(
                      "div",
                      {
                        staticClass: "advertising-item-image",
                        style: {
                          width: "375px",
                          height: "188px",
                          top: 0,
                          left: "375px",
                        },
                      },
                      [
                        _vm.adverArray[2].url
                          ? _c(
                              "el-image",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.adverArray[2].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "le-icon le-icon-morentupian",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _c("el-image", {
                              staticStyle: { width: "100%", height: "188px" },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.adverArray[3]
                  ? _c(
                      "div",
                      {
                        staticClass: "advertising-item-image",
                        style: {
                          width: "375px",
                          height: "188px",
                          top: "188px",
                          left: "375px",
                        },
                      },
                      [
                        _vm.adverArray[3].url
                          ? _c(
                              "el-image",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.adverArray[3].url,
                                  fit: "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "le-icon le-icon-morentupian",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _c("el-image", {
                              staticStyle: { width: "100%", height: "188px" },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                fit: "cover",
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.size == 7
      ? _c(
          "div",
          { staticClass: "rubik-cube" },
          [
            _vm._l(_vm.adverArray, function (item, index) {
              return [
                _c(
                  "div",
                  {
                    key: index,
                    staticClass: "rubik-cube__item",
                    style: {
                      width: _vm.getWidth(item) + "px",
                      height: _vm.getHeight(item) + "px",
                      top: _vm.getTop(item) + "px",
                      left: _vm.getLeft(item) + "px",
                    },
                  },
                  [
                    item.url
                      ? _c(
                          "el-image",
                          {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { src: item.url, fit: "cover" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "le-icon le-icon-morentupian",
                                }),
                              ]
                            ),
                          ]
                        )
                      : _c("el-image", {
                          staticStyle: {
                            width: "100%",
                            height: "100%",
                            "border-top": "1px solid #fff",
                            "border-right": "1px solid #fff",
                          },
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                            fit: "cover",
                          },
                        }),
                  ],
                  1
                ),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }