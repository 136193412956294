var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "playimage"
        ? [
            _c(
              "div",
              {
                staticClass: "top-img",
                style: "padding-top: " + _vm.facade.marginTop / 2 + "px;",
              },
              [_vm._m(0)]
            ),
          ]
        : _vm.content.type === "orderTop"
        ? [
            _c("div", { staticClass: "tab flex align-items-c ft14" }, [
              _c(
                "div",
                {
                  staticClass: "item",
                  style: "color: " + _vm.facade.selectColor + ";",
                },
                [
                  _vm._v("\n        全部\n        "),
                  _c("div", { staticClass: "bg" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item",
                  style: "color: " + _vm.facade.color + ";",
                },
                [
                  _vm._v("\n        未开盒\n        "),
                  _c("div", { staticClass: "bg" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item",
                  style: "color: " + _vm.facade.color + ";",
                },
                [
                  _vm._v("\n        已开盒\n        "),
                  _c("div", { staticClass: "bg" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item",
                  style: "color: " + _vm.facade.color + ";",
                },
                [
                  _vm._v("\n        仓库\n        "),
                  _c("div", { staticClass: "bg" }),
                ]
              ),
            ]),
          ]
        : _vm.content.type === "orderList"
        ? [
            _c(
              "div",
              {
                staticClass: "box-list",
                style:
                  "border-radius:" +
                  _vm.facade.borderRadius / 2 +
                  "px;border-color:" +
                  _vm.facade.borderColor +
                  ";background-color:" +
                  _vm.facade.listBgcolor +
                  ";background-image:url(" +
                  _vm.facade.listBgimage +
                  ")",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "box-time",
                    style: "color: " + _vm.facade.timeColor + ";",
                  },
                  [_vm._v("购买时间 2022-07-27 14:07:36")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "box-product" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("div", { staticClass: "product-info" }, [
                    _c(
                      "div",
                      {
                        staticClass: "product-name",
                        style: "color:" + _vm.facade.blindNameColor,
                      },
                      [_vm._v("\n            盲盒名称\n          ")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "product-box-goods",
                    style:
                      "border-radius:" +
                      _vm.facade.listGoodsRadius / 2 +
                      "px;bbackground-color:" +
                      _vm.facade.listGoodsBgcolor +
                      ";background-image:url(" +
                      _vm.facade.listGoodsBgimage +
                      ")",
                  },
                  [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("div", { staticClass: "product-box-goods-info" }, [
                      _c(
                        "div",
                        {
                          staticClass: "product-box-goods-name",
                          style: "color:" + _vm.facade.productNameColor,
                        },
                        [_vm._v("\n            商品名称\n          ")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "button-box product-box-button" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "product-button",
                            style:
                              "background-color:" +
                              _vm.facade.buttonBgColor +
                              ";color:" +
                              _vm.facade.buttonColor +
                              ";background-image:url(" +
                              _vm.facade.buttonBgimage +
                              ");border-radius:" +
                              _vm.facade.buttonRadiu / 2 +
                              "px",
                          },
                          [_vm._v("\n            炫耀一下\n          ")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "item-flex-center",
        staticStyle: {
          width: "150px",
          height: "150px",
          "margin-left": "112.5px",
        },
      },
      [
        _c("img", {
          staticStyle: { width: "100%", height: "100%" },
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
            alt: "",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "product-img" }, [
      _c("img", {
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "product-box-goods-img" }, [
      _c("img", {
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }