"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = [{
  name: '竖版海报/竖屏视频/邀请函',
  width: 1080,
  height: 1920,
  actual: '1080*1920',
  icon: 'sd-wangye'
}, {
  name: '横版海报/横屏视频/横壁纸',
  width: 1920,
  height: 1080,
  actual: '1920*1080',
  icon: 'sd-wangye'
}, {
  name: '方形海报',
  width: 1242,
  height: 1242,
  actual: '1242*1242',
  icon: 'sd-wangye'
}, {
  name: '全屏海报',
  width: 1242,
  height: 2688,
  actual: '1242*2688',
  icon: 'sd-wangye'
}, {
  name: '营销长图',
  width: 800,
  height: 2000,
  actual: '800*2000',
  icon: 'sd-wangye'
}, {
  name: '公众号首图',
  width: 900,
  height: 383,
  actual: '900*383',
  icon: 'sd-wangye'
}, {
  name: '公众号次图',
  width: 500,
  height: 500,
  actual: '500*500',
  icon: 'sd-wangye'
}, {
  name: '小红书配图',
  width: 1242,
  height: 1660,
  actual: '1242*1660',
  icon: 'sd-wangye'
}, {
  name: '商品主图',
  width: 800,
  height: 800,
  actual: '800*800',
  icon: 'sd-wangye'
}, {
  name: '电商详情页',
  width: 750,
  height: 1000,
  actual: '750*1000',
  icon: 'sd-wangye'
}, {
  name: '电商竖版海报',
  width: 750,
  height: 950,
  actual: '750*950',
  icon: 'sd-wangye'
}, {
  name: '电商横版海报',
  width: 750,
  height: 390,
  actual: '750*390',
  icon: 'sd-wangye'
}, {
  name: '小程序封面',
  width: 520,
  height: 416,
  actual: '520*416',
  icon: 'sd-wangye'
}, {
  name: '朋友圈封面',
  width: 1242,
  height: 1242,
  actual: '1242*1242',
  icon: 'sd-wangye'
}, {
  name: '视频号封面',
  width: 1080,
  height: 1260,
  actual: '1080*1260',
  icon: 'sd-wangye'
}, {
  name: '抖音背景图',
  width: 1125,
  height: 633,
  actual: '1125*633',
  icon: 'sd-wangye'
}, {
  name: '直播背景',
  width: 1242,
  height: 2690,
  actual: '1242*2690',
  icon: 'sd-wangye'
}, {
  name: '平版壁纸',
  width: 2048,
  height: 1536,
  actual: '2048*1536',
  icon: 'sd-wangye'
}, {
  name: '微博版头',
  width: 640,
  height: 640,
  actual: '640*640',
  icon: 'sd-wangye'
}, {
  name: 'A4纸打印',
  width: 1240,
  height: 1754,
  actual: '21*29.7cm',
  icon: 'sd-wangye'
}, {
  name: 'A5纸打印',
  width: 874,
  height: 1240,
  actual: '14.8*21cm',
  icon: 'sd-wangye'
}, {
  name: '证件照一寸',
  width: 295,
  height: 413,
  actual: '2.5*3.5cm',
  icon: 'sd-wangye'
}, {
  name: '证件照二寸',
  width: 413,
  height: 626,
  actual: '3.5*4.9cm',
  icon: 'sd-wangye'
}];