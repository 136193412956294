var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.orderDatalist
    ? _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            title: "退款单信息",
            visible: _vm.dialogVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "description" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("用户信息")]),
              _vm._v(" "),
              _c("div", { staticClass: "acea-row" }, [
                _c("div", { staticClass: "description-term" }, [
                  _vm._v("用户昵称："),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "description-term" }, [
                  _vm._v("联系电话：" + _vm._s(_vm.orderDatalist.user_phone)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("退款单信息")]),
              _vm._v(" "),
              _c("div", { staticClass: "acea-row" }, [
                _c("div", { staticClass: "description-term" }, [
                  _vm._v(
                    "退款单号：" + _vm._s(_vm.orderDatalist.refund_order_sn)
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "description-term" }, [
                  _vm._v(
                    "订单状态：" +
                      _vm._s(
                        _vm._f("orderRefundFilter")(_vm.orderDatalist.status)
                      )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "description-term" }, [
                  _vm._v("商品总数：" + _vm._s(_vm.orderDatalist.refund_num)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "description-term" }, [
                  _vm._v("退款金额：" + _vm._s(_vm.orderDatalist.refund_price)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "description-term" }, [
                  _vm._v("创建时间：" + _vm._s(_vm.orderDatalist.create_time)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "description-term" }, [
                  _vm._v("商家备注：" + _vm._s(_vm.orderDatalist.mer_mark)),
                ]),
              ]),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }