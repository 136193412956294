"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['currentData', 'content', 'facade'],
  data: function data() {
    return {
      list: [],
      lists: [{
        image: 'https://saas.cdn.yunzongbu.cn/image/20211013/5021d314dca0ef49768a69a251e8d84a.png',
        avatorimg: 'https://saas.cdn.yunzongbu.cn/image/20211013/5021d314dca0ef49768a69a251e8d84a.png',
        avator: '发布人',
        time: '2010-02-14',
        name: '新疆天然和田玉青玉无暇好玉在新疆特大优惠专…'
      }, {
        image: 'https://saas.cdn.yunzongbu.cn/image/20210926/b9f1fc183a1bb8a1dbd4d1c1c067ba79.JPG!120a',
        avatorimg: 'https://saas.cdn.yunzongbu.cn/image/20211013/5021d314dca0ef49768a69a251e8d84a.png',
        avator: '发布人',
        time: '2010-02-14',
        name: '新疆天然和田玉青玉无暇好玉在新疆特大优惠专…'
      }, {
        image: 'https://saas.cdn.yunzongbu.cn/image/20211013/5021d314dca0ef49768a69a251e8d84a.png',
        avatorimg: 'https://saas.cdn.yunzongbu.cn/image/20211013/5021d314dca0ef49768a69a251e8d84a.png',
        avator: '发布人',
        time: '2010-02-14',
        name: '新疆天然和田玉青玉无暇好玉在新疆特大优惠专…'
      }, {
        image: 'https://saas.cdn.yunzongbu.cn/image/20210926/b9f1fc183a1bb8a1dbd4d1c1c067ba79.JPG!120a',
        avatorimg: 'https://saas.cdn.yunzongbu.cn/image/20211013/5021d314dca0ef49768a69a251e8d84a.png',
        avator: '发布人',
        time: '2010-02-14',
        name: '新疆天然和田玉青玉无暇好玉在新疆特大优惠专…'
      }]
    };
  },
  watch: {
    currentData: function currentData(val) {
      this.changeData(val);
    }
  },
  created: function created() {
    this.changeData(this.currentData);
  },
  methods: {
    changeData: function changeData(val) {
      if (val && val.length) {
        this.list = val;
      } else {
        this.list = this.lists;
      }
    },
    error: function error(event) {}
  }
};