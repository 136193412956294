var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm ",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "视频封面" } }, [
                _c(
                  "div",
                  {
                    staticClass: "upLoadPicBox",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap("coverImage")
                      },
                    },
                  },
                  [
                    _vm.ruleForm.coverImage
                      ? _c("div", { staticClass: "pictrue" }, [
                          _c("img", {
                            attrs: { src: _vm.ruleForm.coverImage + "!120a" },
                          }),
                        ])
                      : _c("div", { staticClass: "upLoad" }, [
                          _c("i", {
                            staticClass: "el-icon-camera cameraIconfont",
                          }),
                        ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "模版名称", prop: "templateName" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c("el-input", {
                        staticClass: "w300",
                        model: {
                          value: _vm.ruleForm.templateName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "templateName", $$v)
                          },
                          expression: "ruleForm.templateName",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "标签", prop: "labelList" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w300",
                          attrs: { multiple: "", "collapse-tags": "" },
                          model: {
                            value: _vm.ruleForm.labelList,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "labelList", $$v)
                            },
                            expression: "ruleForm.labelList",
                          },
                        },
                        _vm._l(_vm.labelArr, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.id, label: item.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "业务场景", prop: "businessScene" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w300",
                          model: {
                            value: _vm.ruleForm.businessScene,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "businessScene", $$v)
                            },
                            expression: "ruleForm.businessScene",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: 1, label: "拍卖" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 2, label: "商品" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 3, label: "作品" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择分类：", prop: "category" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w300",
                            on: { change: _vm.changeTwoCategory },
                            model: {
                              value: _vm.ruleForm.category,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "category", $$v)
                              },
                              expression: "ruleForm.category",
                            },
                          },
                          _vm._l(_vm.categoryList, function (m, i) {
                            return _c("el-option", {
                              key: i,
                              attrs: { value: m.id, label: m.name },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex align-items-c ml10" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w300",
                            model: {
                              value: _vm.ruleForm.subCategory,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "subCategory", $$v)
                              },
                              expression: "ruleForm.subCategory",
                            },
                          },
                          _vm._l(_vm.categoryTwoList, function (m, i) {
                            return _c("el-option", {
                              key: i,
                              attrs: { value: m.id, label: m.name },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.attrList, function (m, i) {
                return _c(
                  "div",
                  { key: i },
                  [
                    m.attrName == "内容类型"
                      ? [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "内容类型", prop: "contentType" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex align-items-c" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w300",
                                      model: {
                                        value: _vm.ruleForm.contentType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "contentType",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.contentType",
                                      },
                                    },
                                    _vm._l(
                                      m.sysAttrValueList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: "" + index + i,
                                          attrs: {
                                            value: item.id,
                                            label: item.attrValueName,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      : m.attrName == "内容定义"
                      ? [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "内容定义",
                                prop: "contentDefine",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex align-items-c" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w300",
                                      model: {
                                        value: _vm.ruleForm.contentDefine,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "contentDefine",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.contentDefine",
                                      },
                                    },
                                    _vm._l(
                                      m.sysAttrValueList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: "" + index + i,
                                          attrs: {
                                            value: item.id,
                                            label: item.attrValueName,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      : m.attrName == "作品形状"
                      ? [
                          _c(
                            "el-form-item",
                            { attrs: { label: "作品形状", prop: "shapeType" } },
                            [
                              _c(
                                "div",
                                { staticClass: "flex align-items-c" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w300",
                                      model: {
                                        value: _vm.ruleForm.shapeType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "shapeType",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.shapeType",
                                      },
                                    },
                                    _vm._l(
                                      m.sysAttrValueList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: "" + index + i,
                                          attrs: {
                                            value: item.id,
                                            label: item.attrValueName,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "视频级别", prop: "levelType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.levelType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "levelType", $$v)
                        },
                        expression: "ruleForm.levelType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("普通")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("高级")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("定制")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.ruleForm.levelType == 3
                    ? _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w300",
                              attrs: {
                                filterable: "",
                                placeholder: "选择关联商户/用户",
                              },
                              model: {
                                value: _vm.ruleForm.customizedMemberId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "customizedMemberId",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.customizedMemberId",
                              },
                            },
                            _vm._l(_vm.merchantArr, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  value: item.memberId,
                                  label: item.showName,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "视频比例", prop: "ratio" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.ratio,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "ratio", $$v)
                        },
                        expression: "ruleForm.ratio",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "16:9" } }, [
                        _vm._v("16:9"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "9:16" } }, [
                        _vm._v("9:16"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "4:3" } }, [
                        _vm._v("4:3"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "3:4" } }, [
                        _vm._v("3:4"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1:1" } }, [
                        _vm._v("1:1"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.$route.query.id
                ? [
                    _c("el-form-item", { attrs: { label: "标准模版" } }, [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c(
                            "el-upload",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.uploadLoading,
                                  expression: "uploadLoading",
                                },
                              ],
                              attrs: {
                                action: "",
                                "show-file-list": false,
                                "http-request": function (file) {
                                  return _vm.uploadFile(file, "standardFileUrl")
                                },
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("选择文件")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-upload",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.uploadLoading,
                                  expression: "uploadLoading",
                                },
                              ],
                              staticStyle: { "margin-left": "40px" },
                              attrs: {
                                action: "",
                                "show-file-list": false,
                                "http-request": function (file) {
                                  return _vm.setFile(file, "standardConfig")
                                },
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("选择配置文件")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "20px" } },
                            [_vm._v(_vm._s(_vm.ruleForm.standardConfigName))]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "filedName" }, [
                        _vm._v(_vm._s(_vm.ruleForm.standardFileUrlName)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "动态模版" } }, [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c(
                            "el-upload",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.uploadLoading,
                                  expression: "uploadLoading",
                                },
                              ],
                              attrs: {
                                action: "",
                                "show-file-list": false,
                                "http-request": function (file) {
                                  return _vm.uploadFile(file, "dynamicsFileUrl")
                                },
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("选择文件")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-upload",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.uploadLoading,
                                  expression: "uploadLoading",
                                },
                              ],
                              staticStyle: { "margin-left": "40px" },
                              attrs: {
                                action: "",
                                "show-file-list": false,
                                "http-request": function (file) {
                                  return _vm.setFile(file, "dynamicsConfig")
                                },
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("选择配置文件")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "20px" } },
                            [_vm._v(_vm._s(_vm.ruleForm.dynamicsConfigName))]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "filedName" }, [
                        _vm._v(_vm._s(_vm.ruleForm.dynamicsFileUrlName)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "图片库" } }, [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c(
                            "el-upload",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.uploadLoading,
                                  expression: "uploadLoading",
                                },
                              ],
                              ref: "uploadImg",
                              attrs: {
                                action: _vm.fileUrl,
                                headers: _vm.myHeaders,
                                "show-file-list": false,
                                multiple: "",
                                "before-upload": _vm.beforeAvatarUpload,
                                "on-change": _vm.onChange,
                                "http-request": _vm.modeUpload,
                                accept: "image/*",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("选择文件")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "20px" } },
                            [
                              _vm._v(
                                _vm._s(_vm._f("filterImg")(_vm.imageInfoList))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "背景音乐" } }, [
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.modalAudioPicTap },
                      },
                      [_vm._v("选择文件")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "margin-left": "20px" } }, [
                      _vm._v(_vm._s(_vm.ruleForm.backgroundMusic)),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex align-items-c",
              staticStyle: { margin: "100px 0 0 40px" },
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }