var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "projectInfo",
          attrs: {
            title: "商品审核",
            visible: _vm.dialogVisible,
            width: "900px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.projectData && _vm.isShow
            ? _c(
                "el-tabs",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "商品信息", name: "first" } },
                    [
                      _c("div", { staticClass: "acea-row" }, [
                        _c("span", { staticClass: "sp" }, [
                          _vm._v(
                            "商品名称：" + _vm._s(_vm.projectData.store_name)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "sp" }, [
                          _vm._v(
                            "平台分类：" +
                              _vm._s(
                                _vm.projectData.storeCategory
                                  ? _vm.projectData.storeCategory.cate_name
                                  : ""
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "sp" }, [
                          _vm._v(
                            "品牌：" +
                              _vm._s(
                                _vm.projectData.brand
                                  ? _vm.projectData.brand.brand_name
                                  : ""
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "sp" }, [
                          _vm._v(
                            "商品关键字：" + _vm._s(_vm.projectData.keyword)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "sp" }, [
                          _vm._v(
                            "商品单位：" + _vm._s(_vm.projectData.unit_name)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "sp" }, [
                          _vm._v(
                            "运费模板：" +
                              _vm._s(
                                _vm.projectData.temp
                                  ? _vm.projectData.temp.name
                                  : ""
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "sp100" },
                          [
                            _vm._v("\n          商品分类：\n          "),
                            _vm.projectData.merCateId
                              ? _vm._l(
                                  _vm.projectData.merCateId,
                                  function (item, index) {
                                    return _c(
                                      "span",
                                      { key: index, staticClass: "mr10" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            item.category
                                              ? item.category.cate_name
                                              : ""
                                          )
                                        ),
                                      ]
                                    )
                                  }
                                )
                              : _c("span", [_vm._v("-")]),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "sp100" }, [
                          _vm._v(
                            "商品简介：" + _vm._s(_vm.projectData.store_info)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "sp100" }, [
                          _vm._v("\n          商品封面图：\n          "),
                          _c(
                            "div",
                            { staticClass: "demo-image__preview" },
                            [
                              _c("el-image", {
                                staticStyle: { width: "60px", height: "60px" },
                                attrs: {
                                  src: _vm.projectData.image,
                                  "preview-src-list": [_vm.projectData.image],
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.projectData.video_link
                          ? _c("span", { staticClass: "sp100" }, [
                              _vm._v("\n          主图视频：\n           "),
                              _c(
                                "video",
                                {
                                  staticStyle: {
                                    width: "40%",
                                    height: "180px",
                                    "border-radius": "10px",
                                  },
                                  attrs: {
                                    src: _vm.projectData.video_link,
                                    controls: "controls",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                您的浏览器不支持 video 标签。\n              "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "sp100" },
                          [
                            _vm._v("商品轮播图：\n          "),
                            _vm._l(
                              _vm.projectData.slider_image,
                              function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "pictrue" },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "60px",
                                        height: "60px",
                                      },
                                      attrs: {
                                        src: item,
                                        "preview-src-list": [item],
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "商品详情", name: "second" } },
                    [
                      _c("span", { staticClass: "sp100" }, [
                        _vm._v("商品详情："),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "contentPic",
                        domProps: {
                          innerHTML: _vm._s(_vm.projectData.content),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "其他设置", name: "third" } },
                    [
                      _c("span", { staticClass: "sp100" }, [
                        _vm._v("商品排序：" + _vm._s(_vm.projectData.sort)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "third mb20" },
                        [
                          _c("span", [_vm._v("商品推荐：")]),
                          _vm._v(" "),
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.checkboxGroup,
                                callback: function ($$v) {
                                  _vm.checkboxGroup = $$v
                                },
                                expression: "checkboxGroup",
                              },
                            },
                            _vm._l(_vm.recommend, function (item, index) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: index,
                                  attrs: { disabled: "", label: item.value },
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "商品规格", name: "fourth" } },
                    [
                      _c("span", { staticClass: "sp" }, [
                        _vm._v(
                          "商品规格：" +
                            _vm._s(
                              _vm.projectData.spec_type === 0
                                ? "单规格"
                                : "多规格"
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "sp" }, [
                        _vm._v(
                          "佣金设置：" +
                            _vm._s(
                              _vm.projectData.extension_type === 0
                                ? "默认设置"
                                : "单独设置"
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "sp100" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "mb15",
                              staticStyle: { display: "block" },
                            },
                            [_vm._v("商品规格:")]
                          ),
                          _vm._v(" "),
                          _vm.projectData.spec_type === 0
                            ? [
                                _c(
                                  "el-table",
                                  {
                                    staticClass: "tabNumWidth",
                                    attrs: {
                                      data: _vm.OneattrValue,
                                      border: "",
                                      size: "mini",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "图片",
                                        "min-width": "80",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "demo-image__preview",
                                                  },
                                                  [
                                                    _c("el-image", {
                                                      staticStyle: {
                                                        width: "60px",
                                                        height: "60px",
                                                      },
                                                      attrs: {
                                                        src: scope.row.image,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        323865937
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _vm._l(_vm.attrValue, function (item, iii) {
                                      return _c("el-table-column", {
                                        key: iii,
                                        attrs: {
                                          label: _vm.formThead[iii].title,
                                          align: "center",
                                          "min-width": "120",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("span", {
                                                    staticClass: "priceBox",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        scope.row[iii]
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    }),
                                    _vm._v(" "),
                                    _vm.projectData.extension_type === 1
                                      ? [
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "一级返佣(元)",
                                              "min-width": "120",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", {
                                                        staticClass: "priceBox",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            scope.row
                                                              .extension_one
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              102250766
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "二级返佣(元)",
                                              "min-width": "120",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", {
                                                        staticClass: "priceBox",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            scope.row
                                                              .extension_two
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              4284057702
                                            ),
                                          }),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.projectData.spec_type === 1
                            ? [
                                _c(
                                  "el-table",
                                  {
                                    staticClass: "tabNumWidth",
                                    attrs: {
                                      data: _vm.ManyAttrValue,
                                      border: "",
                                      size: "mini",
                                    },
                                  },
                                  [
                                    _vm.manyTabDate
                                      ? _vm._l(
                                          _vm.manyTabDate,
                                          function (item, iii) {
                                            return _c("el-table-column", {
                                              key: iii,
                                              attrs: {
                                                align: "center",
                                                label:
                                                  _vm.manyTabTit[iii].title,
                                                "min-width": "80",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", {
                                                          staticClass:
                                                            "priceBox",
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              scope.row[iii]
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            })
                                          }
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "图片",
                                        "min-width": "80",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "upLoadPicBox",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pictrue tabPic",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: scope.row
                                                              .image,
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        651288578
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _vm._l(_vm.attrValue, function (item, iii) {
                                      return _c("el-table-column", {
                                        key: iii,
                                        attrs: {
                                          label: _vm.formThead[iii].title,
                                          align: "center",
                                          "min-width": "120",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "span",
                                                    { staticClass: "priceBox" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(scope.row[iii])
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    }),
                                    _vm._v(" "),
                                    _vm.projectData.extension_type === 1
                                      ? [
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "一级返佣(元)",
                                              "min-width": "120",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "priceBox",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row
                                                                .extension_one
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1519547738
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "二级返佣(元)",
                                              "min-width": "120",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "priceBox",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row
                                                                .extension_two
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              603129202
                                            ),
                                          }),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审核状态", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "status", $$v)
                        },
                        expression: "ruleForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("通过")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: -1 } }, [
                        _vm._v("拒绝"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.status === -1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "原因", prop: "refusal" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", placeholder: "请输入原因" },
                        model: {
                          value: _vm.ruleForm.refusal,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "refusal", $$v)
                          },
                          expression: "ruleForm.refusal",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }