"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.console = exports.camelize = void 0;
exports.insertNodeAt = insertNodeAt;
exports.removeNode = removeNode;
require("core-js/modules/es6.regexp.replace");
function getConsole() {
  if (typeof window !== 'undefined') {
    return window.console;
  }
  return global.console;
}
var console = exports.console = getConsole();
function cached(fn) {
  var cache = Object.create(null);
  return function cachedFn(str) {
    var hit = cache[str];
    return hit || (cache[str] = fn(str));
  };
}
var regex = /-(\w)/g;
var camelize = exports.camelize = cached(function (str) {
  return str.replace(regex, function (_, c) {
    return c ? c.toUpperCase() : '';
  });
});
function removeNode(node) {
  if (node.parentElement !== null) {
    node.parentElement.removeChild(node);
  }
}
function insertNodeAt(fatherNode, node, position) {
  var refNode = position === 0 ? fatherNode.children[0] : fatherNode.children[position - 1].nextSibling;
  fatherNode.insertBefore(node, refNode);
}