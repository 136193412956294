"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['currentData', 'content', 'facade'],
  data: function data() {
    return {
      list: [],
      lists: [{
        image: 'https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png',
        name: '新疆天然和田玉青玉无暇好玉在新疆特大优惠专…',
        tags: ['试听', '鉴定'],
        price: '￥525.00',
        sign: '235人已听课'
      }, {
        image: 'https://saas.cdn.yunzongbu.cn/image/20211023/7c0028ed94e3f35e7859cac94884f6ed.png',
        name: '新疆天然和田玉青玉无暇好玉在新疆特大优惠专…',
        tags: ['试听', '鉴定'],
        price: '￥525.00',
        sign: '235人已听课'
      }, {
        image: 'https://saas.cdn.yunzongbu.cn/image/20211023/7c0028ed94e3f35e7859cac94884f6ed.png',
        name: '新疆天然和田玉青玉无暇好玉在新疆特大优惠专…',
        tags: ['试听', '鉴定'],
        price: '￥525.00',
        sign: '235人已听课'
      }, {
        image: 'https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png',
        name: '新疆天然和田玉青玉无暇好玉在新疆特大优惠专…',
        tags: ['试听', '鉴定'],
        price: '￥525.00',
        sign: '235人已听课'
      }, {
        image: 'https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png',
        name: '新疆天然和田玉青玉无暇好玉在新疆特大优惠专…',
        tags: ['试听', '鉴定'],
        price: '￥525.00',
        sign: '235人已听课'
      }, {
        image: 'https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a',
        name: '新疆天然和田玉青玉无暇好玉在新疆特大优惠专…',
        tags: ['试听', '鉴定'],
        price: '￥525.00',
        sign: '235人已听课'
      }]
    };
  },
  watch: {
    currentData: function currentData(val) {
      this.changeData(val);
    }
  },
  created: function created() {
    this.changeData(this.currentData);
  },
  methods: {
    changeData: function changeData(val) {
      if (val && val.length) {
        this.list = val;
      } else {
        this.list = this.lists;
      }
    },
    error: function error(event) {}
  }
};