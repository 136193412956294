"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'BrandClassify',
  data: function data() {
    return {
      isChecked: false,
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _product.brandCategoryListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.data;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableData.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableData.limit = val;
      this.getList();
    },
    // 添加
    onAdd: function onAdd() {
      var _this2 = this;
      this.$modalForm((0, _product.brandCategoryCreateApi)()).then(function () {
        return _this2.getList();
      });
    },
    // 编辑
    onEdit: function onEdit(id) {
      var _this3 = this;
      this.$modalForm((0, _product.brandCategoryUpdateApi)(id)).then(function () {
        return _this3.getList();
      });
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this4 = this;
      this.$modalSure().then(function () {
        (0, _product.brandCategoryDeleteApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this4.$message.success(message);
          _this4.getList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this5 = this;
      (0, _product.brandCategoryStatusApi)(row.store_brand_category_id, row.is_show).then(function (_ref3) {
        var message = _ref3.message;
        _this5.$message.success(message);
      }).catch(function (_ref4) {
        var message = _ref4.message;
      });
    }
  }
};