var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "banner",
      style: {
        "background-color": _vm.facade.background_color,
        "padding-bottom":
          _vm.facade.indicator_vertical_align === "bottom" ? "23px" : "0",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "item-flex-center",
          class: { "justify-left": _vm.facade.style === 3 },
        },
        [
          _vm.facade.style === 2
            ? _c("div", { staticClass: "banner-img" }, [
                _c(
                  "div",
                  {
                    staticClass: "no-img item-flex-center",
                    style: {
                      width: _vm.imgW / 2 + "px",
                      height: "214px",
                      "border-radius":
                        _vm.facade.img_radius / 2 +
                        "px " +
                        _vm.facade.img_radius / 2 +
                        "px " +
                        _vm.facade.img_bottom_radius / 2 +
                        "px " +
                        _vm.facade.img_bottom_radius / 2 +
                        "px",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                        alt: "",
                      },
                    }),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "banner-img",
              style: {
                marginLeft:
                  (_vm.facade.style === 2 ? _vm.facade.item_padding / 2 : 0) +
                  "px",
                marginRight:
                  (_vm.facade.style !== 1 ? _vm.facade.item_padding / 2 : 0) +
                  "px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "no-img item-flex-center",
                  style: {
                    width: _vm.imgW / 2 + "px",
                    height: "214px",
                    "border-radius":
                      _vm.facade.img_radius / 2 +
                      "px " +
                      _vm.facade.img_radius / 2 +
                      "px " +
                      _vm.facade.img_bottom_radius / 2 +
                      "px " +
                      _vm.facade.img_bottom_radius / 2 +
                      "px",
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                      alt: "",
                    },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.facade.style === 2 || _vm.facade.style === 3
            ? _c("div", { staticClass: "banner-img" }, [
                _c(
                  "div",
                  {
                    staticClass: "no-img item-flex-center",
                    style: {
                      width: _vm.imgW / 2 + "px",
                      height: "214px",
                      "border-radius":
                        _vm.facade.img_radius / 2 +
                        "px " +
                        _vm.facade.img_radius / 2 +
                        "px " +
                        _vm.facade.img_bottom_radius / 2 +
                        "px " +
                        _vm.facade.img_bottom_radius / 2 +
                        "px",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                        alt: "",
                      },
                    }),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("pointer", {
        attrs: {
          margin: _vm.margin,
          color: _vm.facade.indicator_color,
          type: _vm.facade.indicator_style,
          align: _vm.facade.indicator_align,
          "v-align": _vm.facade.indicator_vertical_align,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }