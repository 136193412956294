"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/utils/index");
var _accounts = require("@/api/accounts");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      accountInfo: {
        balance: 0
      },
      ruleForm: {},
      price: '',
      btnLoading: false,
      showPay: false,
      orderId: '',
      payImg: '',
      payCount: '',
      payPrice: ''
    };
  },
  mounted: function mounted() {
    this.loadAccount();
  },
  methods: {
    loadAccount: function loadAccount() {
      var _this = this;
      (0, _accounts.nftAccountInfoApi)().then(function (res) {
        _this.accountInfo = res.data;
      }).catch(function (message) {});
    },
    changePayMoneyAction: function changePayMoneyAction(money) {
      this.price = money;
      this.payAction(money);
    },
    payAction: function payAction(price) {
      var _this2 = this;
      if (!price || isNaN(price)) {
        this.$message.error('请输入正确的充值金额');
        return false;
      }
      if (price < 100) {
        this.$message.error('平台支持的最小充值金额为 100 元');
        return false;
      }
      this.btnLoading = true;
      (0, _accounts.createNftRechargeOrderApi)({
        pay_price: price,
        type: 2
      }).then(function (res) {
        _this2.btnLoading = false;
        _this2.payImg = res.data.image;
        _this2.payPrice = price;
        _this2.showPay = true;
      }).catch(function (message) {
        _this2.btnLoading = false;
      });
    },
    closePayAction: function closePayAction() {
      this.showPay = false;
      this.orderId = '';
      this.payImg = '';
      this.payCount = '';
      this.payPrice = '';
    },
    buyEndAction: function buyEndAction() {
      this.showPay = false;
      this.orderId = '';
      this.payImg = '';
      this.payCount = '';
      this.payPrice = '';
      this.$router.back();
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};