var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-card", { staticStyle: { "min-height": "90vh" } }, [
        _c("div", { staticClass: "videoMain flex" }, [
          _c(
            "div",
            {
              staticClass: "videoLeftBox",
              style:
                _vm.videoInfo.ratio == "9:16" || _vm.videoInfo.ratio == "3:4"
                  ? "flex-wrap: wrap;"
                  : "flex-direction: column;",
            },
            [
              _c("div", {
                ref: "dplayer",
                style:
                  _vm.videoInfo.ratio == "9:16" || _vm.videoInfo.ratio == "3:4"
                    ? "max-width: 450px;"
                    : "max-width: 850px;",
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  class:
                    _vm.videoInfo.ratio == "9:16" ||
                    _vm.videoInfo.ratio == "3:4"
                      ? "videoHandShCont"
                      : "videoHandCont",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "fragmentBox",
                      class:
                        _vm.videoInfo.ratio == "9:16" ||
                        _vm.videoInfo.ratio == "3:4"
                          ? ""
                          : "flex",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "fragmentItem",
                          class: _vm.selectFram == "start" ? "activeFrag" : "",
                          on: {
                            click: function ($event) {
                              return _vm.onClickFram("start")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "fragmentImgBox" }, [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.videoInfo.start &&
                                  _vm.videoInfo.start.firstImage,
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "fragTit" }, [
                            _vm._v(
                              "入场片段 (" +
                                _vm._s(
                                  _vm.videoInfo.start &&
                                    _vm.videoInfo.start.editNum
                                ) +
                                ")"
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.videoInfo.segments, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "fragmentItem",
                            class:
                              _vm.selectFram == "segments-" + index
                                ? "activeFrag"
                                : "",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onClickSeg(index)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "fragmentImgBox" }, [
                              _c("img", {
                                attrs: { src: item.firstImage, alt: "" },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "fragTit" }, [
                              _vm._v(
                                "循环片段" +
                                  _vm._s(index + 1) +
                                  " (" +
                                  _vm._s(item.editNum) +
                                  ")"
                              ),
                            ]),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "fragmentItem",
                          class: _vm.selectFram == "end" ? "activeFrag" : "",
                          on: {
                            click: function ($event) {
                              return _vm.onClickFram("end")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "fragmentImgBox" }, [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.videoInfo.end &&
                                  _vm.videoInfo.end.firstImage,
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "fragTit" }, [
                            _vm._v(
                              "出场片段 (" +
                                _vm._s(
                                  _vm.videoInfo.end && _vm.videoInfo.end.editNum
                                ) +
                                ")"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "videoBtnBox" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        background: "#6415D7",
                        border: "1px solid #6415D7",
                        width: "80px",
                      },
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "videoRightBox" },
            [
              _c("div", { staticClass: "flex righttt" }, [
                _vm._v("\n          字段设置\n        "),
              ]),
              _vm._v(" "),
              _vm.selectFram.indexOf("segments") > -1
                ? _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _vm._v("片段类型:\n          "),
                      _c(
                        "el-select",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { placeholder: "片段类型", size: "mini" },
                          model: {
                            value: _vm.replateList.dataType,
                            callback: function ($$v) {
                              _vm.$set(_vm.replateList, "dataType", $$v)
                            },
                            expression: "replateList.dataType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: "album", label: "专场" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: "auction", label: "拍品" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.replateList
                ? _vm._l(_vm.replateList.replace, function (ele) {
                    return _c(
                      "div",
                      { key: "x" + ele.sort, staticClass: "flex xhBox" },
                      [
                        _vm.handleTIBox(ele.value, 1).length > 0
                          ? _c(
                              "div",
                              {
                                staticClass: "iibox",
                                class:
                                  _vm.handleTIBox(ele.value).length > 0
                                    ? ""
                                    : "wm",
                              },
                              _vm._l(
                                _vm.handleTIBox(ele.value, 1),
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "updIMgbxo",
                                      style:
                                        _vm.handleTIBox(ele.value).length > 0
                                          ? ""
                                          : "margin-right: 10px;",
                                    },
                                    [
                                      _c("div", { staticClass: "imgUpd" }, [
                                        _c("img", {
                                          attrs: { src: item.value, alt: "" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "imgTagBox" },
                                          [
                                            _c(
                                              "el-tag",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "warning",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.key))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex justify-c flex-wrap",
                                        },
                                        [
                                          item.field
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  item.field,
                                                  function (tag, index) {
                                                    return _c(
                                                      "el-tag",
                                                      {
                                                        key: index,
                                                        staticStyle: {
                                                          "max-width": "100px",
                                                          margin: "5px 0",
                                                        },
                                                        attrs: { closable: "" },
                                                        on: {
                                                          close: function (
                                                            $event
                                                          ) {
                                                            return _vm.onClose(
                                                              item,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-popover",
                                                          {
                                                            attrs: {
                                                              placement: "top",
                                                              trigger: "hover",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  tag.name
                                                                ) +
                                                                _vm._s(
                                                                  tag.format
                                                                    .name
                                                                    ? "-" +
                                                                        tag
                                                                          .format
                                                                          .name
                                                                    : ""
                                                                ) +
                                                                "\n                        "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "tagSpan",
                                                                attrs: {
                                                                  slot: "reference",
                                                                },
                                                                slot: "reference",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    tag.name
                                                                  ) +
                                                                    _vm._s(
                                                                      tag.format
                                                                        .name
                                                                        ? "-" +
                                                                            tag
                                                                              .format
                                                                              .name
                                                                        : ""
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "left",
                                                    width: "750",
                                                    trigger: "click",
                                                  },
                                                  on: {
                                                    hide: _vm.onPopoverHide,
                                                  },
                                                  model: {
                                                    value: item.popover,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "popover",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.popover",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "flex" },
                                                    [
                                                      _c("el-cascader-panel", {
                                                        ref:
                                                          "cascaderRef" +
                                                          item.key,
                                                        refInFor: true,
                                                        attrs: {
                                                          options:
                                                            _vm.filedBindList,
                                                          props: _vm.casProps,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.cascaderChange(
                                                              item.key
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectFiledVal,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selectFiledVal =
                                                              $$v
                                                          },
                                                          expression:
                                                            "selectFiledVal",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _vm.extendList.length > 0
                                                        ? _c(
                                                            "el-cascader-panel",
                                                            {
                                                              attrs: {
                                                                options:
                                                                  _vm.extendList,
                                                                props:
                                                                  _vm.extendProps,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.extendVal,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.extendVal =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "extendVal",
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.optionsList.length > 0
                                                        ? _c(
                                                            "el-cascader-panel",
                                                            {
                                                              attrs: {
                                                                options:
                                                                  _vm.optionsList,
                                                                props:
                                                                  _vm.filedProps,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.optionVal,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.optionVal =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "optionVal",
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "cascFooter",
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.onConfirm(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("确定")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        slot: "reference",
                                                        type: "text",
                                                        size: "mini",
                                                      },
                                                      slot: "reference",
                                                    },
                                                    [_vm._v("关联字段")]
                                                  ),
                                                ],
                                                1
                                              ),
                                          _vm._v(" "),
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                placeholder: "工作流",
                                                size: "mini",
                                              },
                                              model: {
                                                value: item.workflow_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "workflow_id",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.workflow_id",
                                              },
                                            },
                                            _vm._l(
                                              _vm.imgWorkList,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.workflow_id,
                                                  attrs: {
                                                    value: item.workflow_id,
                                                    label: item.workflow_name,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.handleTIBox(ele.value).length > 0
                          ? _c(
                              "div",
                              {
                                staticClass: "ttbox",
                                class:
                                  _vm.handleTIBox(ele.value, 1).length > 0
                                    ? ""
                                    : "mn",
                              },
                              _vm._l(
                                _vm.handleTIBox(ele.value),
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "textBox" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "filedTit" },
                                        [
                                          _c(
                                            "el-tag",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "warning",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.key))]
                                          ),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(item.value)),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex flex-wrap" },
                                        [
                                          _vm._l(
                                            item.field,
                                            function (tag, index) {
                                              return _c(
                                                "el-tag",
                                                {
                                                  key: index,
                                                  staticStyle: {
                                                    margin: "0 10px 10px 0",
                                                  },
                                                  attrs: { closable: "" },
                                                  on: {
                                                    close: function ($event) {
                                                      return _vm.onClose(
                                                        item,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(tag.name) +
                                                      _vm._s(
                                                        tag.format.name
                                                          ? "-" +
                                                              tag.format.name
                                                          : ""
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "left",
                                                width: "750",
                                                trigger: "click",
                                              },
                                              on: { hide: _vm.onPopoverHide },
                                              model: {
                                                value: item.popover,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "popover", $$v)
                                                },
                                                expression: "item.popover",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "flex" },
                                                [
                                                  _c("el-cascader-panel", {
                                                    ref:
                                                      "cascaderRef" + item.key,
                                                    refInFor: true,
                                                    attrs: {
                                                      options:
                                                        _vm.filedBindList,
                                                      props: _vm.casProps,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.cascaderChange(
                                                          item.key
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.selectFiledVal,
                                                      callback: function ($$v) {
                                                        _vm.selectFiledVal = $$v
                                                      },
                                                      expression:
                                                        "selectFiledVal",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.extendList.length > 0
                                                    ? _c("el-cascader-panel", {
                                                        attrs: {
                                                          options:
                                                            _vm.extendList,
                                                          props:
                                                            _vm.extendProps,
                                                        },
                                                        model: {
                                                          value: _vm.extendVal,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.extendVal = $$v
                                                          },
                                                          expression:
                                                            "extendVal",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.optionsList.length > 0
                                                    ? _c("el-cascader-panel", {
                                                        attrs: {
                                                          options:
                                                            _vm.optionsList,
                                                          props: _vm.filedProps,
                                                        },
                                                        model: {
                                                          value: _vm.optionVal,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.optionVal = $$v
                                                          },
                                                          expression:
                                                            "optionVal",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "cascFooter" },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.onConfirm(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("确定")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    slot: "reference",
                                                    type: "text",
                                                    size: "mini",
                                                  },
                                                  slot: "reference",
                                                },
                                                [_vm._v("关联字段")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "工作流",
                                            size: "mini",
                                          },
                                          model: {
                                            value: item.workflow_id,
                                            callback: function ($$v) {
                                              _vm.$set(item, "workflow_id", $$v)
                                            },
                                            expression: "item.workflow_id",
                                          },
                                        },
                                        _vm._l(
                                          _vm.textWorkList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.workflow_id,
                                              attrs: {
                                                value: item.workflow_id,
                                                label: item.workflow_name,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    )
                  })
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }